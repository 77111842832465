
import { appApi } from "src/apiUtils/AppApi";
import { url } from "src/apiUtils/apiPath";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Logger from "src/logger/logger";
import AboutUs from "./aboutus.js/aboutus";
import BannerOne from "./banners/bannerone";
import Footer from "./footer/footer";
import DrawerAppBar from "./nav/mainnavbar";
import Ours from "./ours/ours";
import PinUps from "./pinup/pinup";
import Follower from "./followers/follower";

function MainLanding() {
    const [cardData, setCarddata] = useState({})

    const landingCard = () => {

        appApi(url.LANDING_CARD, "GET", {}).then((result) => {
            Logger("LANDING_CARD >>>>>>>", result,process.env.REACT_APP_ENVIRONMENT);
            if (result.status === true) {
                Logger("REsult>>>>>>>", result.entity);
                setCarddata(result.entity)
            }
        })
    }

    useEffect(() => {
        landingCard()
    }, [])
    return (
        <Box display={"flex"} flexDirection={"column"}  >
            <DrawerAppBar />
            <BannerOne cardData={cardData} />
            <Follower cardData={cardData} />
            <AboutUs />
            {/* <PinUps /> */}
            <Ours />
            <Footer />
        </Box>
    )
}
export default MainLanding