// export const baseUrl = 'http://localhost:3000/api/v1/'
// export const baseUrl = "http://localhost:3006/api/v1/"
// export const baseUrl = "http://localhost:3000/api/v1/"
// export const baseUrlfile = "http://localhost:3000/assets/gallery/"
export const baseUrl = "https://api.hpedigitalclassroom.com:3000/api/v1/"
export const baseUrlfile = "https://api.hpedigitalclassroom.com:3000/assets/gallery/"


export const url = {
   USER_LOGIN: `${baseUrl}auth/user/signin`,
   CENTER_ALL_LIST: `${baseUrl}All/center`,
   CITY_ALL_LIST: `${baseUrl}All/city`,
   ADMIN_DASH_CARD: `${baseUrl}dashboard/card`,
   CREATE_USERS: `${baseUrl}create/user/detail`,
   UPDATE_USERS: `${baseUrl}update/user`,
   USERS_LIST: `${baseUrl}all/user/detail`,
   USERS_BLOCK: `${baseUrl}block/user`,
   USER_LIST_WISE_CITY: `${baseUrl}all/user/detail/city`,
   CENTER_CREATE: `${baseUrl}create/center`,
   CENTER_UPDATE: `${baseUrl}update/center`,
   CENTER_DELETE: `${baseUrl}Delete/center`,
   LANDING_CARD: `${baseUrl}landing/card`,
   SEND_MAIL: `${baseUrl}send/mail`,
   CHART_DASHBOARD_BAR: `${baseUrl}dashboard/chart`,
   PARTNER_LIST: `${baseUrl}all/partner/detail`,
   GET_ALL_GALLERY_IMAGE: `${baseUrl}All/gallery`,
   POST_GALLERY_IMAGE: `${baseUrl}Add/gallery`,
   DELETE_GALLERY_IMAGE: `${baseUrl}Delete/gallery`,
   CREATE_GALLERY_IMAGE: `${baseUrl}Add/gallery`
}