import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import Logger from "src/logger/logger";
import { useState } from "react";
import { appApi } from "src/apiUtils/AppApi";
import { url } from "src/apiUtils/apiPath";
import { ToastContainer, toast } from 'react-toastify';


function ContactUs() {
    const [datas, setDatas] = useState({
        Name: '',
        Phone: '',
        Email: '',
        Subject: 'Contact Us',
        Message: ''
    })
    const [errors, setErrors] = useState({});
    const notifySuccess = (msg) => toast.success(
        msg,
        {
            width: "10%",
            pauseOnHover: false,
            autoClose: 4000,
            position: 'bottom-center'
        }
    );

    const notify = (msg) => toast.error(
        msg,
        {
            width: "10%",
            pauseOnHover: false,
            autoClose: 4000,
            position: 'bottom-center'
        }
    );
    const handleFormSubmit = () => {
        const data = datas
        if (datas.Name !== "" && datas.Message !== "") {
            if (datas.Email !== "" || datas.Phone !== "") {
                appApi(url.SEND_MAIL, "POST", { 'Content-Type': 'application/json' }, JSON.stringify(data)).then((result) => {
                    Logger("FORMS", result, data);
                    if (result.status === true) {
                        notifySuccess("Email Sent Successfully")
                        setDatas({
                            Email: "",
                            Name: "",
                            Phone: "",
                            Subject: 'Contact - Us',
                            Message: ""
                        })
                    }
                })
            }
            else {
                notify("Email & Phone both cannot be empty")
            }
        }
        else {
            notify("Name & Message Caanot be Empty")
        }

    }
    // const validateForm = () => {
    //     let isValid = true;
    //     // let isPhoneValid = true;
    //     const errors = {};

    //     // Email validation
    //     Logger("Phone >>>>>>>>>>>>>>", datas.Phone);
    //     if (datas.Phone !== null && datas.Phone !== '' && !datas.Email || !/\S+@\S+\.\S+/.test(datas.Email)) {
    //         errors.Email = 'Please enter a valid email address';
    //         isValid = false;
    //         Logger("email>>>>>>>>", datas.Email);
    //     }


    //     if (datas.Email !== null && datas.Email !== '' && !datas.Phone || !/^\d{10}$/.test(datas.Phone)) {
    //         errors.Phone = 'Please enter a valid 10-digit phone number';
    //         isValid = false;
    //         Logger("phone>>>>>>>>", datas.Phone);
    //     }
    //     if (!datas.Name || datas.Name.trim() === '') {
    //         errors.Name = 'Name is required';

    //     }

    //     if (!datas.Message || datas.Message.trim() === '') {
    //         errors.Message = 'Message is required';

    //     }


    //     // if (!(isEmailValid || isPhoneValid )) {
    //     //     errors.Form = 'Please enter at least a valid email address or a valid 10-digit phone number or fill in Name and Message fields';
    //     //   }
    //     setErrors(errors); // Set errors in state
    //     return errors.Email || errors.Phone && errors.Name && errors.Message
    // }


    const styledInput = {
        bgcolor: '#FFFFFF',
        borderRadius: '10px',
        input: {
            "&::placeholder": {
                color: "#808080"
            },
        },
        "& fieldset": {
            border: 'none'
        }
    }
    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'white'
        }}>
            <Box display={'flex'} marginTop={4} marginBottom={4} >
                <Box display={{
                    lg: 'flex',
                    md: 'flex',
                    sm: 'none',
                    xs: 'none'
                }}>
                    <img src='assets/feedback/Group 611.svg' alt="img" />
                </Box>
                <Box sx={{ height: { lg: '100%', md: '100%', sm: '70%', xs: '60%' }, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box  >
                        <Typography variant="h3" textAlign={'center'}>Contact Us</Typography>
                        <Typography color={'background.main'}>Welcome To Our Classrooms. We Are Glad To Have You Around.</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: '100%',
                width: '100%'
            }} >
                <Grid container
                    justifyContent={'center'}
                    alignItems={'center'}
                    direction={{
                        lg: 'row',
                        md: 'row',
                        sm: 'column-reverse',
                        xs: 'column-reverse'
                    }} >
                    <Grid item xs={8} >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{
                                height: {
                                    lg: '60%',
                                    md: '60%',
                                    sm: '40%',
                                    xs: '40%'
                                },
                                width: {
                                    lg: '60%',
                                    md: '60%',
                                    sm: '90%',
                                    xs: '90%'
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }} >
                                <Stack>
                                    <Stack>
                                        <Typography alignSelf={'start'} variant="h3" color={'background.main'}>Address:</Typography>
                                        <Typography variant="h6">Plot No. PAP- A-12/6/2, Near kasbe Godown Khed MIDC phase 4 chakan, industrial area, Nighoje 410501.</Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography alignSelf={'start'} variant="h3" color={'background.main'}>Phone Number:</Typography>
                                        <Typography variant="h6">18001201687</Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography alignSelf={'start'} variant="h3" color={'background.main'}>Email:</Typography>
                                        <Typography variant="h6">limton.hpe@gmail.com</Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{
                        height: '100%',
                        width: '100%',
                    }} >
                        <Box display={'flex'} sx={{
                            height: '100%',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2
                        }} >
                            <Box >
                                <img src='assets/feedback/contact_us.svg' alt="img" />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box height={'100%'} width={'100%'} bgcolor={'background.main'} justifyContent={'space-around'}>
                <Stack sx={{
                    justifyContent: 'space-around',
                    alignItems: 'center'
                }}>
                    <Stack spacing={1}
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <Typography variant="h3" color={'white'} >Mail Us</Typography>
                        <Typography variant="h3" color={'button.primary'} >HPe Digital Classrooms</Typography>
                        <Typography variant="h3" color={'white'} >We Would Love To Hear From You!</Typography>
                        <Typography color={'white'}>If you have any questions, please call us or fill in the form below and we will get back to you very soon.</Typography>
                    </Stack>
                    <Box sx={{
                        height: '100%',
                        width: '100%',
                        paddingTop: 2,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        {/* <Grid
                            container
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                direction: 'row',
                                height: '80%',
                                width: '80%'
                            }} >
                            <Grid item xs={6} bgcolor={'yellowgreen'} alignItems={'center'}>
                                <TextField />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField />
                            </Grid>
                        </Grid> */}
                        <Box
                            sx={{
                                height: '50%',
                                width: '48%',
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center',
                                flexDirection: 'row'
                                // {
                                //     xs: 'row',
                                //     sm: ''
                                // }
                            }}>
                            <Stack spacing={6} sx={{
                                width: '50%'
                            }} >
                                <Box display={"flex"} flexDirection={"column"} >
                                    <TextField
                                        value={datas.Name}
                                        placeholder="Name"
                                        sx={styledInput}
                                        onChange={(e) => setDatas({ ...datas, Name: e.target.value })}
                                        error={errors.Name}
                                    // helperText={errors.Name}
                                    />
                                    {errors.Name && (
                                        <Typography variant="body1" color="error">
                                            {errors.Name}
                                        </Typography>
                                    )}
                                </Box>
                                <Box display={"flex"} flexDirection={"column"}>
                                    <TextField
                                        value={datas.Email}
                                        placeholder="Email"
                                        sx={styledInput}
                                        onChange={(e) => setDatas({ ...datas, Email: e.target.value })}
                                        error={errors.Email}
                                    // helperText={errors.Email}
                                    />
                                    {datas.Phone && errors.Email && (
                                        <Typography variant="body1" color="error">
                                            {errors.Email}
                                        </Typography>
                                    )}
                                </Box>
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                height: '50%',
                                width: '48%',
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center'
                            }}>
                            <Stack spacing={6} sx={{
                                width: '50%',
                            }}  >
                                <Box display={"flex"} flexDirection={"column"}>
                                    <TextField
                                        value={datas.Phone}
                                        placeholder="Phone Number"
                                        sx={styledInput}
                                        onChange={(e) => setDatas({ ...datas, Phone: e.target.value })}
                                        error={errors.Phone}
                                    // helperText={errors.Phone}
                                    />
                                    {datas.Email && errors.Phone && (
                                        <Typography variant="body1" color="error">
                                            {errors.Phone}
                                        </Typography>
                                    )}
                                </Box>
                                <Box display={"flex"} flexDirection={"column"}>
                                    <TextField
                                        value={datas.Message}
                                        placeholder="Message"
                                        sx={styledInput}
                                        onChange={(e) => setDatas({ ...datas, Message: e.target.value })}
                                        error={errors.Message}
                                    // helperText={errors.Message}
                                    />
                                    {errors.Message && (
                                        <Typography variant="body1" color="error">
                                            {errors.Message}
                                        </Typography>
                                    )}
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Stack>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'50%'} bgcolor={'background.main'}>
                    <Button
                        sx={{
                            width: '15%'
                        }}
                        variant="contained"
                        style={{
                            backgroundColor: '#F8B239',
                            boxShadow: 'none',
                        }}
                        onClick={handleFormSubmit}
                    >
                        Send Message
                    </Button>
                </Box>
            </Box>
            <ToastContainer />
        </Box >
    )
}

export default ContactUs