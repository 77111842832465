import PropTypes from 'prop-types';
import { Box, Grid, Typography } from "@mui/material"
import Counter from "src/pages/counter"



function Follower(props) {
    const { cardData } = props
    const counterData = [cardData?.student_count, cardData?.center_count, cardData?.partner_count, 28]
    const counterTitle = ["Students Enrolled", "Classroom", "Partners", "States Covered"]
    return (

        <Box
            sx={{
                paddingBottom: { sm: "2%", md: "2%", xs: "2%" },
                paddingTop: { sm: "2%", md: "2%", xs: "2%" },
                display: "flex",
                height: "100%",
                bgcolor: "background.main",
                flexDirection: "column",
                color: "common.white",
                textAlign: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    // marginTop: "1%"
                }}
            >
                <Typography fontSize={{ lg: "30px", md: "30px", sm: "25px", xs: "20px" }} width={{ lg: "40%", md: "60%", sm: "90%", xs: "90%" }}>
                    We Have Something To Be Proud Of Our Students, Teachers And Followers
                </Typography>

            </Box>
            <Grid container >
                {counterTitle.map((e, i) => (
                    <Grid item key={i} lg={3} md={6} sm={6} xs={12} marginTop={"2%"} marginBottom={"2%"}>
                        <Counter number={counterData[i]} title={e} />
                    </Grid>
                ))}
            </Grid>
        </Box>

    )
}

Follower.propTypes = {
    cardData: PropTypes.shape({
        student_count: PropTypes.number,
        center_count: PropTypes.number,
        partner_count: PropTypes.number,
    }),
};

export default Follower