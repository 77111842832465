import { Box, Typography, Grid, Stack, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appApi } from "src/apiUtils/AppApi";
import { url } from "src/apiUtils/apiPath";
import Counter from "src/pages/counter";
// import backgroundImageOne from "";
const backgroundOne = {
    display: "flex",
    bgcolor: "blue",
    height: "100%",
    paddingTop: 10,
    alignContent: "end",
    justifyContent: "center",
    alignItems: "end"
}
function BannerOne(props) {
    const navigate = useNavigate()

    return (
        <Box display={"flex"} sx={{ height: { lg: "100vh", md: "100vh", sm: "70vh", xs: "40vh" }, width: "100%", flexDirection: "column", overflow: "hidden", position: "relative" }}>
            <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', position: "relative" }}>
                <Box display={"flex"} justifyContent={"end"} marginTop={7} height={"40%"} >
                    <Box width={"25%"} marginTop={2} display={"flex"} justifyContent={"end"} >
                        <img src="/assets/landing/Group 636.png" alt="" />
                    </Box>
                </Box>
                <Box display={"flex"} width={"100%"} height={"100%"}>
                    <Box display={"flex"} width={"100%"} alignItems={"end"}>
                        <Box display={"flex"} height={"50%"} >
                            <Box width={{ lg: "70%", md: "70%", sm: "70%", xs: "40%" }}>
                                <img src="/assets/landing/Group 627.svg" alt="" />
                            </Box>
                        </Box>

                        <Box display={"flex"} flexGrow={1} height={"100%"} alignItems={"end"}>
                            <Box width={{ lg: "100%", md: "100%", sm: "100%", xs: "40%" }} height={"50%"} display={"flex"}  >
                                <img src="/assets/landing/Group 637.png" alt="" />
                            </Box>
                        </Box>
                        <Box display={"flex"} height={"100%"} justifyContent={"end"} marginBottom={"1%"}>
                            <Box width={{ lg: "80%", md: "80%", sm: "80%", xs: "40%" }} display={"flex"} alignItems={"end"} >
                                <img src="/assets/landing/Group 627.svg" alt="" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box display={"flex"} justifyContent={"end"} height={"100%"} width={"100%"} position={'absolute'} right={0} alignItems={"center"} top={{ lg: 40, md: 40, sm: 30, xs: 30 }}>
                    <Box display={"flex"} justifyContent={"end"} width={{ lg: "50%", md: "50%", sm: "40%", xs: "40%" }} >
                        <img src="/assets/landing/Mask group.svg" alt="" width={"100%"} />
                    </Box>
                </Box>
            </Box>
            <Box display={"flex"} justifyContent={"start"} height={"100%"} width={"100%"} position={'absolute'} left={0} alignItems={"center"} top={{ lg: 20, md: 10, sm: 30, xs: 30 }} paddingLeft={"5%"} paddingBottom={8}>
                <Box display={"flex"} width={"50%"} justifyContent={"center"} height={{ lg: "80%", md: "80%", sm: "80%", xs: "50%" }} flexDirection={"column"}>
                    <Typography fontSize={{ lg: "50px", md: "40px", sm: "30px", xs: "15px" }} fontWeight={5} justifyContent={"center"}>
                        Helping you learn<br />
                    </Typography>
                    <Typography width={"70%"} fontSize={{ lg: "30px", md: "25px", sm: "20px", xs: "10px" }} color={"text.main"} fontWeight={2}>
                        More than {props.cardData.student_count} Students In {props.cardData.center_count} Head Center
                    </Typography>
                    <Box marginTop={"5%"}>
                        <Button
                            onClick={
                                () => {
                                    navigate('/login')
                                }
                            }
                            sx={{
                                paddingLeft: "10%",
                                paddingRight: "10%",
                                "&:hover": {
                                    backgroundColor: "button.primary"
                                },
                                bgcolor: "button.primary"

                            }}>
                            <Typography fontSize={{ lg: "30px", md: "20px", sm: "20px", xs: "10px" }} color={"common.white"}>
                                Login
                            </Typography>
                        </Button>
                    </Box>
                </Box>

            </Box>
            {/* <Box
                sx={{
                    position: "absolute",
                    display: "flex",
                    width: { lg: "40%", md: "100%", sm: "100%" },
                    height: "100%",
                    // alignItems: "center",
                    // marginTop: { sm: "10%" },
                    paddingLeft: "5%",
                    flexDirection: "column",
                    bgcolor: "blue",
                    alignContent: "center",
                    justifyContent: "center"
                }}
            >

                <Box bgcolor={"green"} height={"40%"}>
                    <Box height={"30%"} bgcolor={"pink"} maxWidth={"40%"}>
                        <Box height={"100%"}>
                            <Typography  >
                                Helping you learn about the world.
                            </Typography>
                        </Box>
                        {/* <Typography variant="h4" color={"text.main"}>
                            More than 36981 Students In 39 Head Center
                        </Typography> */}
            {/* <Box marginTop={"5%"}>
                            <Button
                                onClick={
                                    () => {
                                        navigate('/login')
                                    }
                                }
                                sx={{
                                    paddingLeft: "10%",
                                    paddingRight: "10%",
                                    "&:hover": {
                                        backgroundColor: "button.primary"
                                    },
                                    bgcolor: "button.primary"

                                }}>
                                <Typography variant="h5" color={"common.white"}>
                                    Login
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box> */}
        </Box >
    )
}
export default BannerOne