// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  [{
    title: 'Dashboard',
    path: '/dashboard/admin',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Partner',
    path: '/dashboard/partner',
    icon: icon('ic_user'),
  },


  {
    title: 'Center',
    path: '/dashboard/center',
    icon: icon('ic_user'),
  },
  // {
  //   title: 'centers',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'students',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },

  {
    title: 'Teacher',
    path: '/dashboard/teacher',
    icon: icon('ic_user'),
  },
  {
    title: 'Student',
    path: '/dashboard/student',
    icon: icon('ic_user'),
  },
  {
    title: 'Gallery',
    path: '/dashboard/gallery',
    icon: icon('ic_blog'),
  },
  {
    title: 'logout',
    path: '/landing',
    icon: icon('ic_lock'),
  },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: icon('ic_disabled'),
    // },
  ],
  [{
    title: 'Dashboard',
    path: '/dashboard/partner/main',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Center',
    path: '/dashboard/center',
    icon: icon('ic_user'),
  },
  {
    title: 'Teacher',
    path: '/dashboard/teacher',
    icon: icon('ic_user'),
  },
  {
    title: 'Student',
    path: '/dashboard/student',
    icon: icon('ic_user'),
  },

  // {
  //   title: 'centers',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'students',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  {
    title: 'logout',
    path: '/landing',
    icon: icon('ic_lock'),
  },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: icon('ic_disabled'),
    // },
  ],
  [{
    title: 'Dashboard',
    path: '/dashboard/teacher/main',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Student',
    path: '/dashboard/student',
    icon: icon('ic_user'),
  },
  // {
  //   title: 'centers',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'students',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  {
    title: 'logout',
    path: '/landing',
    icon: icon('ic_lock'),
  },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: icon('ic_disabled'),
    // },
  ]
];

export default navConfig;
