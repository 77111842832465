import { Helmet } from 'react-helmet-async';
import { filter, result } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Modal,
    Box,
    Grid,
    List,
    ListItem,
    TextField,
    Divider,
    Radio,
    Tab
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// import { Box, Tab } from '@mui/material';
// import { Radio } from '@mui/joy';
import { BlockOutlined, CancelOutlined, DeleteOutline } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logger from "src/logger/logger";
import { appApi } from 'src/apiUtils/AppApi';
import { url } from 'src/apiUtils/apiPath';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import CenterToStudentAndTeacher from './centertoStudentandTeacher';
// import { eventManager } from 'react-toastify/dist/core';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'location', label: 'Location', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'student_count', label: 'Total Students', alignRight: false },
    { id: 'option', label: 'option', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function PartnerToCenter(props) {
    const userDtl = localStorage.getItem("userLoginDetail")
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('user.parnter_detail.user_name');

    const [filterName, setFilterName] = useState('');
    const [isLoading, setIsloading] = useState(false)

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [modalOpen, setModalOpen] = useState(false);
    const [centerOpen, setCenterOpen] = useState(false);
    const [centerDetail, setCenterDetail] = useState([])
    const [cityDetail, setCityDetail] = useState([]);
    const [centerList, setCenterList] = useState([])
    const [selectedCenter, setSelectedCenter] = useState({})
    const [cityId, setCityId] = useState()
    const [partnerWise, setPartnerWise] = useState([])
    const [centerNewDetail, setCenterNewDetail] = useState({
        "center_id": null,
        "createdBy": null,
        "center_name": null,
        "center_location": null
    })
    const [selectedValue, setSelectedValue] = useState('');
    const [editMode, setEditMode] = useState(false)
    const [value, setValue] = useState('1');
    const [nextPage, setNextPage] = useState(false)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        appApi(url.CITY_ALL_LIST, "GET", {}).then((result) => {
            if (result && result.status === true) {
                setCityDetail(result.entity)
            } else {
                notify(result.message)

            }
        })
        handleGetCenterList()
    }, [])
    const notify = (msg) => toast.error(
        msg,
        {
            pauseOnHover: false,
            autoClose: 4000,
            position: 'bottom-center'
        }
    );
    const notifySuccess = (msg) => toast.success(
        msg,
        {
            pauseOnHover: false,
            autoClose: 4000,
            position: 'bottom-center'
        }
    );
    const handleModalOpen = () => {
        setEditMode(false)
        setCenterNewDetail((per) => ({
            ...per,
            "center_id": null,
            "createdBy": null,
            "center_name": null,
            "center_location": null
        }))
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
        handleCloseMenu()
    };
    const handleCenterOpen = () => {
        setCenterOpen(true);
    };
    const handleCenterClose = () => {
        setCenterOpen(false);
    };
    const handleOpenMenu = (event, value) => {
        setSelectedCenter(value)
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = centerList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
        Logger("filterName.length", filterName.length);
        if (filterName.length >= 3) {
            handleGetCenterList()
        }
        if (filterName.length === 1) {
            // setFilterName('');
            handleGetCenterList()
        }
    };

    const handleGetCenterList = () => {
        setEditMode(false)
        setIsloading(true)
        appApi(url.CENTER_ALL_LIST, "POST", { 'Content-Type': 'application/json' }, JSON.stringify({ filter_name: filterName.length === 1 ? '' : filterName, "my_user_role": '1', "filter_user_id": props?.partnerDtl?.partner_id })).then((result) => {
            setIsloading(false)
            Logger("handleGetCenterList", result);
            if (result && result.status === true) {
                setCenterList(result.entity)
            } else {
                notify(result.message)

            }
        })
    }

    const handleGetCityWiseParnterList = (value) => {
        appApi(`${url.USER_LIST_WISE_CITY}/${value}`, "GET", {}).then((result) => {
            Logger("handleGetCityWiseParnterList", result);
            if (result && result.status === true) {
                setPartnerWise(result.entity)
            } else {
                notify(result.message)

            }
        })
    }

    const handleGetCenterBlock = () => {
        Logger("selected >>>>>", selectedCenter);
        appApi(url.CENTER_DELETE, "DELETE", { 'Content-Type': 'application/json' }, JSON.stringify(selectedCenter))
            .then((result) => {
                Logger("handleGetCenterBlock", result);
                if (result && result.status === true) {
                    handleCloseMenu()
                    handleGetCenterList()
                    notifySuccess(selectedCenter.is_deleted === true ? "Blocked" : "Un-Blocked")

                }
            })
    }
    const handleCreateCenter = () => {
        const data = {
            ...centerNewDetail, createdBy: props.partnerDtl.partner_id,
        }
        Logger("handle Create user", data, JSON.parse(userDtl).user.id)

        if (centerNewDetail.center_name !== null && centerNewDetail.center_name !== '' && centerNewDetail.center_location !== null && centerNewDetail.center_location !== '') {
            appApi(url.CENTER_CREATE, "POST", { 'Content-Type': 'application/json' }, JSON.stringify(data))
                .then((result) => {
                    if (result && result.status === true) {
                        handleModalClose()
                        handleGetCenterList()
                        notifySuccess("Center created successfully")
                    } else {
                        notify(result.message)
                    }
                })
        }
        else {
            notify('Center Name & City is Mandatory')
        }
    }

    const handleCenterUpdate = () => {
        const data = {
            ...centerNewDetail
        }
        Logger("handle Create user", data, JSON.parse(userDtl).user.id)


        if (centerNewDetail.center_name !== null && centerNewDetail.center_name !== '' && centerNewDetail.center_location !== null && centerNewDetail.center_location !== '') {
            appApi(url.CENTER_UPDATE, "PUT", { 'Content-Type': 'application/json' }, JSON.stringify(data))
                .then((result) => {
                    if (result && result.status === true) {
                        setEditMode(false)
                        handleModalClose()
                        handleGetCenterList()
                        notifySuccess("Center updated successfully")
                    } else {
                        notify(result.message)
                    }
                })
        }
        else {
            notify('Center Name & City is Mandatory')
        }
    }

    const handleEditmodal = () => {

        const data = centerList[selectedCenter.index]

        setCenterNewDetail((pre) => ({
            ...pre,
            "center_id": data.id,
            "center_name": data.center_name,
            "center_location": data.center_location,
        }))
        setEditMode(true)
        setModalOpen(true)
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - centerList.length) : 0;

    // const filteredUsers = applySortFilter(partnerList, getComparator(order, orderBy), filterName);

    // const isNotFound = !filteredUsers.length && !!filterName;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        // color: "primary.mainSecond",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}  >
                            <Tab label="Center" value="1" sx={{ fontSize: '20px' }} />
                        </TabList>
                    </Box>
                    <TabPanel value="1">  <>

                        <Container>


                            <Card>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} paddingRight={5}>
                                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} title={"Center"} />
                                    <Typography variant='h4'>{props?.partnerDtl?.partner_name}</Typography>
                                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleModalOpen}>
                                        Add Center
                                    </Button>
                                </Stack>

                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800 }}>
                                        <Table>
                                            <UserListHead
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={TABLE_HEAD}
                                                rowCount={centerList.length}
                                                numSelected={selected.length}
                                                onRequestSort={handleRequestSort}
                                                onSelectAllClick={handleSelectAllClick}
                                            />
                                            <TableBody>
                                                {centerList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                                    const { id, name, role, status, company, avatarUrl, isVerified } = row;

                                                    const selectedUser = selected.indexOf(row) !== -1;

                                                    return (
                                                        <TableRow hover tabIndex={-1} role="checkbox" selected={selectedUser}>


                                                            <TableCell component="th" onClick={() => {
                                                                props.callBack({
                                                                    status: true,
                                                                    center_name: centerList[page * rowsPerPage + i].center_name,
                                                                    center_id: centerList[page * rowsPerPage + i].id,
                                                                    center_location: centerList[page * rowsPerPage + i].center_location
                                                                })
                                                            }}>

                                                                <Typography variant="subtitle2" noWrap>
                                                                    {row?.center_name || "-"}
                                                                </Typography>

                                                            </TableCell>

                                                            <TableCell align="left">{row?.center_location || "-"}</TableCell>
                                                            <TableCell align="left">
                                                                <Label color={(row?.is_deleted === true ? 'error' : 'success')}>{sentenceCase((row?.is_deleted === true ? "blocked" : "Active"))}</Label>
                                                            </TableCell>
                                                            <TableCell align="left" >
                                                                <Typography textAlign={'start'}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{row?.student_count || "0"}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <IconButton size="large" color="inherit" onClick={(event) => {
                                                                    if (page > 0) {
                                                                        handleOpenMenu(event, { center_id: row?.id, is_deleted: !row?.is_deleted, index: page * rowsPerPage + i })
                                                                        Logger("page>>>>>>", page, rowsPerPage, rowsPerPage + i);
                                                                    }
                                                                    else {
                                                                        handleOpenMenu(event, { center_id: row?.id, is_deleted: !row?.is_deleted, index: i })
                                                                        Logger("page>>>>>>", page, rowsPerPage, i);
                                                                    }
                                                                }}>
                                                                    <Iconify icon={'eva:more-vertical-fill'} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {emptyRows > 0 && (
                                                    <TableRow style={{ height: 53 * emptyRows }}>
                                                        <TableCell colSpan={6} />
                                                    </TableRow>
                                                )}
                                            </TableBody>

                                            {centerList && centerList.length === 0 && (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                            <Paper
                                                                sx={{
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                <Typography variant="h6" paragraph>
                                                                    {isLoading ? 'Loading .....' : 'No Data Found'}
                                                                </Typography>
                                                            </Paper>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={centerList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Card>
                        </Container>

                        <Popover
                            open={Boolean(open)}
                            anchorEl={open}
                            onClose={handleCloseMenu}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{
                                sx: {
                                    p: 1,
                                    width: 140,
                                    '& .MuiMenuItem-root': {
                                        px: 1,
                                        typography: 'body2',
                                        borderRadius: 0.75,
                                    },
                                },
                            }}
                        >
                            <MenuItem onClick={() => { handleEditmodal() }}>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                Edit
                            </MenuItem>

                            <MenuItem sx={{ color: (selectedCenter && selectedCenter.is_deleted === true) ? "green" : 'error.main' }} onClick={handleGetCenterBlock}>
                                <Box display={"flex"} alignItems={"center"}>
                                    <BlockOutlined sx={{ mr: 1.5 }} />
                                    {selectedCenter && selectedCenter.is_deleted === true ? "Blocked" : "Un-Blocked"}
                                </Box>
                            </MenuItem>
                        </Popover>
                        <Modal
                            open={modalOpen}
                            onClose={handleModalClose}
                        >
                            <Box sx={{ ...style, width: 500, flexDirection: "column" }}>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            Center Detail
                                        </Typography>
                                    </Box>
                                    <Box flexGrow={0}>
                                        <IconButton onClick={handleModalClose}>
                                            <CancelOutlined />
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Divider />
                                <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
                                    <List >
                                        <ListItem>
                                            <TextField
                                                required
                                                label={"Center Name"}
                                                name="center_name"
                                                placeholder='Enter center name'
                                                // variant="outlined"
                                                // focused
                                                value={centerNewDetail.center_name}
                                                onChange={(event) => {
                                                    setCenterNewDetail((pre) => ({
                                                        ...pre,
                                                        center_name: event.target.value
                                                    }))
                                                }}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <TextField
                                                required
                                                // select
                                                fullWidth
                                                label={"City"}
                                                name="user_city"
                                                placeholder='Enter city'
                                                // variant="outlined"
                                                // focused
                                                value={centerNewDetail.center_location}
                                                onChange={(event) => {
                                                    setCenterNewDetail((pre) => ({
                                                        ...pre,
                                                        center_location: event.target.value
                                                    }))
                                                    // setCityId(event.target.value)
                                                    // handleGetCityWiseParnterList(event.target.value)
                                                }
                                                }
                                            >
                                                {/* {cityDetail && cityDetail.length !== 0 ? cityDetail.map((e, i) => {
                                                    return <MenuItem value={e.id} >{e.city}</MenuItem>
                                                }) : <MenuItem value="">none</MenuItem>} */}
                                            </TextField>
                                        </ListItem>
                                        {/* {editMode === false ? <ListItem>
                                            <TextField
                                                required
                                                select
                                                fullWidth
                                                label={"Partner of center"}
                                                placeholder='Select center'
                                                name="createdBy"
                                                onChange={(event) => {
                                                    setCenterNewDetail((pre) => ({
                                                        ...pre,
                                                        createdBy: event.target.value
                                                    }))
                                                }
                                                }

                                            >

                                                {partnerWise && partnerWise.length !== 0 ? partnerWise.map((e, i) => {
                                                    return <MenuItem value={e.user_id} >{e.user_name}</MenuItem>
                                                }) : <MenuItem value="">none</MenuItem>}

                                            </TextField>
                                        </ListItem> : null} */}
                                    </List>

                                    {editMode === true ? <Button
                                        onClick={handleCenterUpdate}
                                        sx={{
                                            color: "common.black",
                                            paddingLeft: "10%",
                                            paddingRight: "10%",
                                            "&:hover": {
                                                backgroundColor: "button.primary"
                                            },
                                            bgcolor: "button.primary"
                                        }}>
                                        Update
                                    </Button> : <Button
                                        onClick={handleCreateCenter}
                                        sx={{
                                            color: "common.black",
                                            paddingLeft: "10%",
                                            paddingRight: "10%",
                                            "&:hover": {
                                                backgroundColor: "button.primary"
                                            },
                                            bgcolor: "button.primary"
                                        }}>
                                        Create
                                    </Button>}


                                </Box>
                            </Box>
                        </Modal >

                        <ToastContainer />
                    </></TabPanel>
                </TabContext>
            </Box>
            {/* {nextPage === true ? <CenterToStudentAndTeacher /> : null} */}
        </>
    );
}
