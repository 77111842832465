import Faq from "./faq"
import Footer from "../main/footer/footer"
import DrawerAppBar from "../main/nav/mainnavbar"

function FaqMain() {

    return (
        <>
            <DrawerAppBar />
            <Faq />
            <Footer />
        </>
    )
}

export default FaqMain