import { Box, Button, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
// import { mt } from "date-fns/locale"
// import HorizontalScroll from "src/pages/scrollimage"

function AboutUs() {
    const navigate = useNavigate()
    return (
        <Box sx={{

            display: "flex",
            flexDirection: "column",
            paddingBottom: { lg: "0", sm: "2%", md: "2%", xs: "2%" },
            paddingTop: { lg: "2", sm: "2%", md: "2%", xs: "2%" },
            height: { lg: "100vh", sm: "100vh", md: "100vh", xs: "100vh" },
            position: "relative"
            // justifyContent: "space-around"
        }}>
            <Box height={"100%"} justifyContent={"space-between"} display={"flex"} flexDirection={"column"} position={"relative"}>
                <Box display={"flex"} justifyContent={"end"} marginTop={7} height={"30%"} >
                    <Box width={"20%"} marginTop={2} display={"flex"} justifyContent={"end"} >
                        <img src="/assets/landing/Group 636.png" alt="" />
                    </Box>
                </Box>
                <Box display={"flex"} justifyContent={"start"} marginBottom={7} height={"15%"} >
                    <Box width={"20%"} marginTop={2} display={"flex"} justifyContent={"start"} >
                        <img src="/assets/landing/Group 627.svg" alt="" />
                    </Box>
                </Box>
                <Box position="absolute" height={"100%"}>
                    <Box height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-around"}>
                        <Box
                            sx={{

                                display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center",
                                textAlign: "center",
                                flexDirection: "column"
                            }}
                        >
                            <Typography fontSize={{ lg: "30px", md: "30px", sm: "25px", xs: "20px" }} >
                                About Us
                            </Typography>
                            <Typography fontSize={{ lg: "30px", md: "30px", sm: "25px", xs: "20px" }} color={"text.main"} >
                                This advance platform delivers e-learning content curated for our students
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} >
                            <Grid container display={"flex"} justifyContent={"center"} >

                                <Grid item lg={8} md={8} sm={7} xs={12} >
                                    <Box sx={{ paddingLeft: 5, paddingRight: 5, paddingBottom: 5 }}  >
                                        <Box
                                            sx={{
                                                paddingBottom: 5,
                                                display: "flex",
                                                // justifyContent: "center",
                                                // alignItems: "center",
                                                textAlign: "justify",
                                                flexDirection: "column",

                                            }}
                                        >
                                            <Typography fontSize={{ lg: "25px", md: "25px", sm: "20px", xs: "15px" }} lineHeight={"1.5"} fontWeight={"5"}>
                                                HPE’s digital classroom solutions provide an environment that supports learner‐centric pedagogies, integrated curriculum and multiple assessment approach. It allows for an interactive and stimulating learning experience through collaborative and personal learning settings in virtual and physical learning spaces.
                                            </Typography>

                                        </Box>
                                        <Button 
                                        variant="contained" 
                                        onClick={() => {
                                            navigate(`/about`)
                                        }}
                                        sx={{
                                            paddingLeft: "5%",
                                            paddingRight: "5%",
                                            "&:hover": {
                                                backgroundColor: "button.primary"
                                            },
                                            bgcolor: "button.primary"
                                        }}>
                                            <Typography variant="h5" color={"common.white"}>
                                                Read more
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item lg={3.5} md={4} sm={5} xs={8} display={"flex"} justifyContent={"center"}>
                                    <Box sx={{
                                        display: "flex",
                                        height: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
                                        width: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
                                        // padding: "3%",
                                        marginLeft: 5,
                                        marginRight: { lg: 0, md: 5, sm: 5, xs: 5 },
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        bgcolor: "background.main",
                                        flexDirection: "column",
                                        borderRadius: "5px"
                                    }}>

                                        <img src="/assets/landing/Rectangle 688.svg" alt="" height={"100%"} width={"100%"} style={{ padding: 5 }} />

                                        <Box display={"flex"} height={"40px"} alignItems={"center"}>
                                            <Box
                                                width={8}
                                                height={8}
                                                borderRadius="50%"
                                                bgcolor={'common.white'}
                                                sx={{
                                                    cursor: 'pointer',
                                                    marginRight: "5px"
                                                }}

                                            />
                                            <Box
                                                width={8}
                                                height={8}
                                                borderRadius="50%"
                                                bgcolor={'common.white'}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* <Divider sx={{
                bgcolor: 'background.main'
            }} /> */}
        </Box >
    )
}

export default AboutUs