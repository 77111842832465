import { AppBar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
// import { Image } from '@mui/icons-material';

const drawerWidth = 240;
const navItems = [
    {
        name: 'Home',
        path: "/landing"
    },
    {
        name: 'About',
        path: "/about"
    },
    {
        name: 'Contact us',
        path: "/contactus"
    },
    {
        name: 'Feedback',
        path: "/feedback"
    },
    {
        name: 'Support',
        path: "/support"
    },
    {
        name: 'Gallery',
        path: "/gallery"
    },
    {
        name: 'FAQ',
        path: "/faq"
    },
];

export default function DrawerAppBar(props) {
    const navigate = useNavigate()
    const [mobileOpen, setMobileOpen] = React.useState(false);




    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box
                paddingTop={2}
                paddingBottom={2}
                variant="h6"
                component="div"
            // sx={{}}
            >
                <img src='assets/landing/hp_enterprise_logo.webp' alt='Logo' width={"160px"} height={"80px"} />
            </Box>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding >
                        <ListItemButton sx={{ textAlign: 'center' }} onClick={() => {
                            navigate(item.path)
                        }}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: 'center' }}>
                        <div>
                            <Button

                                // href="https://nielit.gov.in/content/nsqf" target="_blank"
                                onClick={() => {
                                    navigate('/student-portal')
                                }}
                                sx={{
                                    // paddingLeft: "10%",
                                    // paddingRight: "10%",
                                    "&:hover": {
                                        backgroundColor: "button.primary"
                                    },
                                    bgcolor: "button.primary"

                                }}>
                                <Typography variant='h6' color={"common.white"}>
                                    Student Portal
                                </Typography>
                            </Button>
                        </div>
                    </Box>
                </ListItem>
            </List>
        </Box >
    );

    // const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar component="nav" sx={{ bgcolor: "common.white" }} position='static' >
                <Toolbar>
                    <IconButton
                        color="common.black"
                        aria-label="open drawer"
                        edge="start"

                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 0.4, display: { sm: 'block' } }}
                    >
                        <img src='assets/landing/hp_enterprise_logo.webp' alt='Logo' width={"140px"} height={"66px"} />
                    </Box>
                    <Box sx={{ flexGrow: 0.7, display: { xs: 'none', sm: 'block' }, justifyContent: 'center' }}>
                        <Box width={{
                            lg: '67%',
                            md: '80%',
                            sm: '100%',
                            xs: '100%'
                        }} display={"flex"} justifyContent={"space-between"}>
                            {navItems.map((item) => (

                                <Button key={item} sx={{ color: 'common.black', ":active": "button.main" }} onClick={() => {
                                    navigate(item.path)
                                }}>
                                    <Typography variant='h6' >  {item.name}</Typography>
                                </Button>

                            ))}
                        </Box>
                    </Box>
                    {/* <Box
                        // variant="h6"
                        // component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    > */}
                    <Box
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        <div>
                            <Button
                                // href="https://nielit.gov.in/content/nsqf" target="_blank"
                                onClick={() => {
                                    navigate('/student-portal')
                                }}
                                // onClick={
                                //     () => {
                                //         window.location.href("https://nielit.gov.in/content/nsqf")
                                //     }
                                // }
                                sx={{
                                    // paddingLeft: "10%",
                                    // paddingRight: "10%",
                                    "&:hover": {
                                        backgroundColor: "button.primary"
                                    },
                                    bgcolor: "button.primary"

                                }}>
                                <Typography variant='h6' color={"common.white"}>
                                    Student Portal
                                </Typography>
                            </Button>
                        </div>
                    </Box>
                    {/* </Box> */}
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    // container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box >
    );
}
