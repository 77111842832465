import { Box, Button, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

function AboutTwo() {
    const navigate = useNavigate()
    return (
        <Box sx={{ bgcolor: 'background.main', display: 'flex', justifyContent: 'center', alignItems: 'center', height: { lg: '30%', md: '30%', sm: '20%', xs: '20%' }, width: '100%' }}>
            <Box display={'flex'} justifyContent={'space-around'} height={'100%'} >
                <Stack justifyContent={'space-around'} alignItems={'center'}   >
                    <Stack justifyContent={'center'} alignItems={'center'}>
                        <Typography variant="h3" color={'white'} >Play, Explore And Learn.</Typography>
                        <Typography color={'#F8B239'}>Direct to your Door</Typography>
                    </Stack>
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate('/contactus')
                        }}
                        sx={{
                            width: '40%'
                        }}
                        style={{
                            backgroundColor: '#F8B239',
                            boxShadow: 'none',
                        }}
                    >
                        Mail Us
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}
export default AboutTwo