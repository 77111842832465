import Feedback from "./feedback"
import Footer from "../main/footer/footer"
import DrawerAppBar from "../main/nav/mainnavbar"

function FeedbackMain() {

    return (
        <>
            <DrawerAppBar />
            <Feedback />
            <Footer />
        </>
    )
}

export default FeedbackMain