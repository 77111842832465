import ContactUs from "./contact"
import Footer from "../main/footer/footer"
import DrawerAppBar from "../main/nav/mainnavbar"

function ContactUsMain() {

    return (
        <>
            <DrawerAppBar />
            <ContactUs />
        </>
    )
}

export default ContactUsMain