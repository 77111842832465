import { Box, Grid, Typography } from "@mui/material";

function Support() {

    return (
        <Box sx={{
            height: '92%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'white'
        }}>
            <Box display={'flex'} marginTop={4} marginBottom={4} >
                <Box >
                    <img src='assets/feedback/Group 611.svg' alt="img" />
                </Box>
                <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box  >
                        <Typography variant="h3" textAlign={'center'}>Support</Typography>
                        <Typography color={'background.main'}>Any Kind Of Support Need</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: '80%',
                width: '100%',
                // display: 'flex',
            }} >
                <Grid container
                    justifyContent={'space-around'}
                    alignItems={'center'}
                    direction={{
                        lg: 'row',
                        md: 'row',
                        sm: 'column-reverse',
                        xs: 'column-reverse'
                    }} >
                    <Grid item xs={8} sx={{
                        height: '100%',
                        width: '100%',
                    }}>
                        <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{
                                height: { lg: '60%', md: '70%', sm: '90%', xs: '90%' },
                                width: { lg: '60%', md: '70%', sm: '90%', xs: '90%' },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }} >
                                <Typography variant="subtitle1" fontSize={20}>We provide academic support by a wide variety of instructional methods, educational services provided to students in the effort to help them accelerate their learning progress, catch up with their peers and meet learning standards.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box display={'flex'} sx={{
                            height: '100%',
                            width: '100%',
                            justifyContent: 'flex-end',
                            padding: 2
                            // padding: 2
                        }} >
                            <Box alignSelf={'start'} sx={{
                                height: '85%',
                                width: '90%'
                            }}>
                                <img src='assets/feedback/support.svg' alt="img" />
                            </Box>
                            {/* <Box alignSelf={'end'} >
                                <img src='assets/feedback/Group 620.png' alt="img" />
                            </Box> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}

export default Support