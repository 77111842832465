// import { MapOutlined } from "@mui/icons-material"
import { Avatar, Box, List, ListItem, ListItemAvatar, Typography } from "@mui/material"
// import { mt } from "date-fns/locale"
// import HorizontalScroll from "src/pages/scrollimage"

function Footer() {
    const fontSize = {
        lg: '24px',
        md: '24px',
        sm: '12px',
        xs: '12px'
    }
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: { lg: "1%", sm: "5%", md: "5%", xs: "5%" },
            paddingTop: { lg: "1%", sm: "5%", md: "5%", xs: "5%" },
            paddingLeft: "5%",
            paddingRight: "5%",
            justifyContent: "space-around",
            bgcolor: "#1E1E1E"
        }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    flexDirection: "column",
                    color: "common.white"
                }}
            >
                <Typography fontSize={fontSize}>
                    Contact Us
                </Typography>
                <Typography color={"background.main"} fontSize={fontSize}>
                    Welcome To Our Classrooms. We Are Glad To Have You Around.
                </Typography>
            </Box>
            <Box display={"flex"} flexDirection={{ md: "column", sm: "column", lg: "row", xs: "column" }} justifyContent={"center"}>
                <Box width={'100%'} >
                    <List >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar variant="square" src="/assets/icons/map.png" alt="" />
                            </ListItemAvatar>
                            <Typography color="common.white" fontSize={fontSize} >
                                Plot No. PAP- A-12/6/2, Near kasbe Godown Khed MIDC phase 4 chakan, industrial area, Nighoje 410501.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar variant="square" src="/assets/icons/phone.png" alt="" />
                            </ListItemAvatar>
                            <Typography color="common.white" fontSize={fontSize}>
                                +91 9008522866
                            </Typography>
                        </ListItem>
                        <ListItem >
                            <ListItemAvatar>
                                <Avatar variant="square" src="/assets/icons/mail.png" alt="" />
                            </ListItemAvatar>
                            <Typography color="common.white" fontSize={fontSize} >
                                limton.hpe@gmail.com
                            </Typography>

                        </ListItem>
                    </List>
                </Box>
                <Box display={"flex"} width={{ lg: "50%", md: "100%", sm: "100%", xs: "100%" }} justifyContent={{ lg: "end", sm: "center", xs: "center" }} alignItems={"end"}>
                    <List>
                        <ListItem>
                            <Typography color="common.white" fontSize={fontSize}>
                                © 2023 HPE . All Rights Reserved
                            </Typography>
                        </ListItem>

                    </List>
                </Box>
            </Box>
        </Box >
    )
}

export default Footer