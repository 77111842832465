import { Box, Typography } from "@mui/material";
import React from "react";
import CountUp from "react-countup";

export default function Counter({ number, title }) {
    return (
        <Box className="number">
            <Typography fontSize={{ lg: "30px", md: "30px", sm: "25px", xs: "20px" }} >
                <CountUp duration={5} className="counter" end={number} />
            </Typography>
            <Typography fontSize={{ lg: "30px", md: "30px", sm: "25px", xs: "20px" }} variant="h4">{title}</Typography>
        </Box>
    );
}