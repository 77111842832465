import { Box, Button, Grid, List, ListItem, Stack, Typography } from "@mui/material";

function About() {
    const paths = [
        {
            name: "HPE eLearning",
            path: "https://education.hpe.com/ww/en/training/elearning.html"
        },
        {
            name: "HPE Digital Learner",
            path: "https://education.hpe.com/ww/en/training/marketing/digital-learner-landing.html"
        },
        {
            name: "Ms-word",
            path: "https://www.microsoft.com/en-us/microsoft-365/word"
        },
        {
            name: "Ms-Office",
            path: "https://www.microsoft.com/en-us/microsoft-365/microsoft-office"
        },
        {
            name: "Ms-Excel",
            path: "https://www.microsoft.com/en-us/microsoft-365/excel"
        },
        {
            name: "Ms-Powerpoint",
            path: "https://www.microsoft.com/en-us/microsoft-365/powerpoint"
        }
    ]
    return (
        <Box sx={{
            // height: '100%',
            // width: '100%',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'white',
            paddingBottom: 2,
            overflow: 'hidden'
        }}>
            <Box display={'flex'} marginTop={4} marginBottom={4} >
                <Box display={{
                    lg: 'flex',
                    md: 'flex',
                    sm: 'none',
                    xs: 'none'
                }}>
                    <img src='assets/feedback/Group 611.svg' alt="img" />
                </Box>
                <Box sx={{ height: { lg: '100%', md: '100%', sm: '70%', xs: '60%' }, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box  >
                        <Typography variant="h4" textAlign={'center'}>Student Portal</Typography>
                        <Typography color={'background.main'}>Journey to Excellence. Your Learning Hub</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: '100%',
                width: '100%',
                // display: 'flex',     
            }} >
                <Grid container
                    justifyContent={'center'}
                    alignItems={'center'}
                    direction={{
                        lg: 'row',
                        md: 'row',
                        sm: 'column-reverse',
                        xs: 'column-reverse'
                    }} >
                    <Grid item xs={8} >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                            <Box
                                sx={{
                                    height: {
                                        lg: '60%',
                                        md: '60%',
                                        sm: '40%',
                                        xs: '40%'
                                    },
                                    width: {
                                        lg: '60%',
                                        md: '60%',
                                        sm: '90%',
                                        xs: '90%'
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    alignSelf: "center",
                                    textAlign: "justify"
                                }} >
                                <Stack
                                    sx={{
                                        height: '100%',
                                        width: '100%'
                                    }}>
                                    <List sx={{
                                        listStyleType: 'disc',
                                        height: '100%',
                                        width: '100%',
                                        // pl: 2
                                    }} >
                                        {paths.map((program, index) => (
                                            <ListItem
                                                key={index}
                                                component="a"
                                                sx={{
                                                    display: 'list-item',
                                                    justifyContent: { md: "flex-start" },
                                                    alignItems: { md: "center" },
                                                    height: '100%',
                                                    width: '100%'
                                                }}

                                            >
                                                <Typography variant="h4">{program.name}</Typography>
                                                <Box sx={{ height: { xs: "20%" }, width: {xs:'85%', sm:'85%'}, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
                                                    <a href={program.path} target="_blank" rel="noreferrer" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre-wrap' }}>{program.path}</a>
                                                </Box>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{
                        height: '100%',
                        width: '100%',
                    }} >
                        <Box display={'flex'} sx={{
                            height: '100%',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2,
                            position: "relative"
                        }} >
                            <Box display={{
                                lg: 'flex',
                                md: 'flex',
                                sm: 'none',
                                xs: 'none'
                            }} >
                                <img src='assets/feedback/gallery.svg' alt="img" />
                            </Box>
                            {/* <Box width={"100%"} position={"absolute"} display={"flex"} justifyContent={"end"}>
                                <img src='assets/landing/Group 636 (1).svg' alt="img" />
                            </Box> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}

export default About