export const appApi = (url, method, headers = {}, body = '') =>
  new Promise(async (resolve, reject) => {
    let options = {
      method,
      headers: {
        ...headers,
      },
    };
    if (body) {
      options = { ...options, body };
    }
    try {
      const response = await fetch(url, options);
      const json = await response.json();
      if (json.status) {
        if (json.status === 'false') {
          reject(json.reason);
        } else {
          resolve(json);
        }
      } else {
        resolve(json);
      }
    } catch (error) {
      reject(error);
    }

  })
