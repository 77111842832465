import { Helmet } from 'react-helmet-async';
import { filter, result } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Modal,
    Box,
    Grid,
    List,
    ListItem,
    TextField,
    Divider,
    Radio,
    CardMedia,
    Input
} from '@mui/material';
// import { Radio } from '@mui/joy';
import { BlockOutlined, CancelOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appApi } from 'src/apiUtils/AppApi';
import { baseUrlfile, url } from 'src/apiUtils/apiPath';
import Logger from "src/logger/logger";
// components
// import Label from '../components/label';
import Iconify from '../components/iconify';
// import Scrollbar from '../components/scrollbar';
// // sections
// import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// // mock
// import USERLIST from '../_mock/user';
// import CenterToStudentAndTeacher from './centertoStudentandTeacher';
// import { eventManager } from 'react-toastify/dist/core';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'location', label: 'Location', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'options', label: 'Options', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function GalleryPage() {
    const [modalOpen, setModalOpen] = useState(false);

    const [imgList, setImgList] = useState([])
    const [imageFile, setImageFile] = useState()
    const getAllGallery = () => {
        appApi(url.GET_ALL_GALLERY_IMAGE, "GET", {}).then((result) => {
            if (result && result.status === true) {
                setImgList(result.entity)
            }
        })
    }

    useEffect(() => {
        getAllGallery()
    },[])

    const notify = (msg) => toast.error(
        msg,
        {
            pauseOnHover: false,
            autoClose: 4000,
            position: 'bottom-center'
        }
    );
    const notifySuccess = (msg) => toast.success(
        msg,
        {
            pauseOnHover: false,
            autoClose: 4000,
            position: 'bottom-center'
        }
    );
    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleDelete = (event) => {
        Logger("Event>>>>>>>>>", event);
        const data = {
            "gallery_id": event.id
        }
        appApi(url.DELETE_GALLERY_IMAGE, "DELETE", { "Content-Type": "application/json" }, JSON.stringify(data)).then((result) => {
            Logger("Result>>>>>>>>>", result);
            if (result && result.status === true) {
                notifySuccess(result.message)
                getAllGallery()
            }
            else {
                notify(result.message)
            }
        })
    }

    const handleCreate = () => {
        Logger("Event>>>>>>>>>",imageFile );
        const data = new FormData();
        data.append('gallery_image', imageFile)
        appApi(url.POST_GALLERY_IMAGE, "POST", { }, data).then((result) => {
            Logger("Result>>>>>>>>>", result);
            if (result && result.status === true) {
                notifySuccess(result.message)
                getAllGallery()
                setModalOpen(false)
            }
            else {
                notify(result.message)
            }
        })
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        // color: "primary.mainSecond",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return (
        <>
            <Helmet>
                <title> Gallery </title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Gallery
                    </Typography>

                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleModalOpen}>
                        Add Image
                    </Button>
                </Stack>

                <Card>
                    <Grid container sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                        {
                            imgList.map((e, i) => {
                                return <Grid key={e.id} item xs={12} sm={6} md={4} lg={4} sx={{
                                    m: 2,
                                    bgcolor: '#e9ecf2',
                                    borderRadius: 2
                                }} >
                                    {/* <CardMedia
                                        component={'img'}
                                        image={e.url} 
                                        height={200}
                                        title={e.Date}
                                        alt={`shot${i}`} 
                                        style={{ objectFit: 'contain' }}
                                         /> */}
                                    <img
                                        src={`${baseUrlfile}${e.image_url}`}
                                        alt={`Gallery Shot ${i}`}
                                        style={{
                                            height: 200,
                                            width: '100%',
                                            objectFit: 'fill',
                                            objectPosition: 'center center'
                                        }} />
                                    <Typography textAlign={'center'} fontWeight={'bold'}><br />Uploaded On - {e.createdAt} <br /><br /></Typography>
                                    <IconButton
                                        sx={{
                                            bgcolor: '#2065d1',
                                            color: '#fbffff',
                                            top: '-79%',
                                            left: '92%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                        onClick={() => {
                                            handleDelete(e)
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            })
                        }
                    </Grid>
                </Card>
            </Container>
            <ToastContainer />
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
            >
                <Box sx={{ ...style, width: '40%', flexDirection: "column" }}>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box flexGrow={1}>
                            <Typography>
                                Gallery Upload
                            </Typography>
                        </Box>
                        <Box flexGrow={0}>
                            <IconButton onClick={handleModalClose}>
                                <CancelOutlined />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Box display={"flex"} flexDirection={"row"} paddingTop={10} paddingBottom={10}>

                        Selet Image for Gallery : &nbsp;&nbsp;
                        <input type="file" accept="image/*" onChange={(event) => { setImageFile(event.target.files[0]) }} />

                    </Box>
                    <Box display={"flex"} justifyContent={"end"}>
                        <Button variant='contained' onClick={handleCreate}>
                            Upload
                        </Button>
                    </Box>
                </Box>
            </Modal >
        </>
    );
}
