import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from "react";
import Logger from "src/logger/logger";


function Faq() {
    const styledAccordion = {
        border: '1px solid #000000',
        borderRadius: '10px'
    }
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        Logger("Panel >>>>>>>>", panel, isExpanded);
        setExpanded(isExpanded ? panel : null);
    };

    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'white'
        }}>
            <Box display={'flex'} marginTop={4} marginBottom={4} >
                <Box display={{
                    lg: 'flex',
                    md: 'flex',
                    sm: 'none',
                    xs: 'none'
                }}>
                    <img src='assets/feedback/Group 611.svg' alt="img" />
                </Box>
                <Box sx={{ height: { lg: '100%', md: '100%', sm: '70%', xs: '60%' }, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box  >
                        <Typography variant="h3" textAlign={'center'}>FAQ</Typography>
                        <Typography color={'background.main'}>Questions In Your Mind</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: '100%',
                width: '100%',
                // display: 'flex',     
            }} >
                <Grid container
                    // bgcolor='red'
                    // height={'100%'}
                    // width={'100%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    direction={{
                        lg: 'row',
                        md: 'row',
                        sm: 'column-reverse',
                        xs: 'column-reverse'
                    }} >
                    <Grid item xs={8} >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box
                                sx={{
                                    height: {
                                        lg: '80vh',
                                        md: '80vh',
                                        sm: '100%',
                                        xs: '100%'
                                    },
                                    // {
                                    //     lg: '60%',
                                    //     md: '60%',
                                    //     sm: '40%',
                                    //     xs: '40%'
                                    // },
                                    width: {
                                        lg: '60%',
                                        md: '60%',
                                        sm: '90%',
                                        xs: '90%'
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }} >
                                <Stack sx={{ height: '45%', width: '100%', justifyContent: 'space-between' }} >
                                    <Accordion sx={styledAccordion} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowDownIcon />}>
                                            <Typography>How can I contact www.hpedigitalclassroom.com?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>You can either call us on 18001201687 (9:30 am to 5:30 pm) and speak with our customer service representative or write to us at Customersupporthpedc@clarity-medical.com for any queries about hpedigitalclassroom.com.</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion sx={styledAccordion} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
                                    >
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowDownIcon />}>
                                            <Typography>I live outside India, can I order be a part of it?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>How can I contact www.hpedigitalclassroom.com?</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion sx={styledAccordion} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
                                    >
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowDownIcon />}>
                                            <Typography>What is HPe Digital Classrooms?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>How can I contact www.hpedigitalclassroom.com?</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion sx={styledAccordion} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
                                    >
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowDownIcon />}>
                                            <Typography>How this program and HPe Digital Classrooms works?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>How can I contact www.hpedigitalclassroom.com?</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{
                        height: '100%',
                        width: '100%',
                    }} >
                        <Box display={'flex'}
                            sx={{
                                height: '100%',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }} >
                            <Box alignItems={'flex-end'} justifyContent={'flex-end'}>
                                <img src='assets/feedback/faq_group.svg' alt="img" />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}

export default Faq