import Support from "./support"
import Footer from "../main/footer/footer"
import DrawerAppBar from "../main/nav/mainnavbar"

function SupportMain() {

    return (
        <>
            <DrawerAppBar />
            <Support />
            <Footer />
        </>
    )
}

export default SupportMain