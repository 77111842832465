import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Select, MenuItem, Typography, CircularProgress } from '@mui/material';
import Logger from "src/logger/logger";
import { url } from 'src/apiUtils/apiPath';
import { appApi } from 'src/apiUtils/AppApi';
// components
import { useChart } from '../../../components/chart';



// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({ subheader, chartLabels, chartData, ...other }) {
  const navigate = useNavigate()
  const [userDetail, setUserDtl] = useState(JSON.parse(localStorage.getItem("userLoginDetail")))
  const userRole = localStorage.getItem("role")
  const [chartBarData, setChartData] = useState()

  const [partner, setPartner] = useState()
  const [partnerID, setPartnerID] = useState(0)
  const [chartBarLabel, setChartBarLabel] = useState([])
  const [chartPlotData, setChartPlotData] = useState([])
  const [count, setCount] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState(null)
  useEffect(() => {
    if (userDetail && userDetail.user) {
      Logger("LABEL >>> one", userDetail.user);
      chartApi()
    }
  }, [userDetail, partnerID])

  useEffect(() => {
    chartPlotDataMethod()
    chartLabelData()
  }, [chartBarData])

  const chartApi = () => {
    if (userDetail && userDetail?.user && userRole === "0") {
      Logger("LABEL >>> two", userDetail.user);
      Logger("USER-0");
      const data = {
        user_id: userDetail.user.id,
        user_role: userRole,
        partner_id: partnerID
      }
      partnerListApi()

      chartDataApi(data)
    }
    if (userDetail && userDetail?.user && userRole === "1") {
      Logger("USER-1>>>>>>>>>>>", userDetail?.user?.id);
      const data = {
        partner_id: userDetail?.user?.id,
        user_role: userRole
      }
      chartDataApi(data)
    }
    if (userDetail && userDetail?.user?.user_center_detail && userRole === "2") {
      Logger(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      Logger("USER-2", userDetail?.user?.user_center_detail.center_id);
      const data = {
        center_id: userDetail?.user?.user_center_detail?.center_id,
        user_role: userRole
      }
      chartDataApi(data)
    }
  }
  const chartDataApi = (data) => {
    // Logger("LABEL >>> four", data);
    Logger("chart data api>>>>>>>>", data);
    appApi(url.CHART_DASHBOARD_BAR, "POST", { 'Content-Type': 'application/json' }, JSON.stringify(data)).then((result) => {
      Logger("LANDING_CARD >>>>>>>", result);
      if (result.status === true) {
        Logger("REsult>>>>>>> DaTA", result.entity);
        setChartData(result.entity)

      }
    })
  }
  const partnerListApi = (data) => {
    Logger("LABEL >>> four", data);
    appApi(url.PARTNER_LIST, "GET", {}).then((result) => {
      Logger("LANDING_CARD >>>>>>>", result);
      if (result.status === true) {
        Logger("REsult>>>>>>> partner", result.entity);
        setPartner(result.entity)

      }
    })
  }
  const chartLabelData = () => {
    const label = []
    if (chartBarData && userRole === "0") {
      const value = chartBarData.map((e) => { return partnerID !== 0 ? e.center_name : e.partner_name })
      Logger("LABEL >>>>", value);
      label.push(...value)
    }
    if (userRole === "2") {
      const value = ["student"]
      Logger("LABEL >>>> student", value);
      label.push(...value)
    }
    if (chartBarData && userRole === "1") {
      const value = chartBarData.map((e) => { return e.center_name })
      Logger("LABEL >>>> partner", value);
      label.push(...value)
    }
    Logger("LABEL >>>>", label);
    setChartBarLabel(label)
  }

  const chartPlotDataMethod = () => {
    const Data = []
    if (chartBarData && userRole === "0") {
      let value = []
      if (partnerID !== 0) {
        value = [
          {
            name: 'Students',
            type: 'bar',
            fill: 'solid',
            data: chartBarData.map((e) => { return e.student_count }),
          },
          {
            name: 'Teachers',
            type: 'bar',
            fill: 'solid',
            data: chartBarData.map((e) => { return e.teacher_count })
          },
        ]
      } else {
        value = [{
          name: 'Center',
          type: 'bar',
          fill: 'solid',
          data: chartBarData.map((e) => { return e.center_count })
        },
        {
          name: 'Students',
          type: 'bar',
          fill: 'solid',
          data: chartBarData.map((e) => { return e.student_count.student }),
        },
        {
          name: 'Teachers',
          type: 'bar',
          fill: 'solid',
          data: chartBarData.map((e) => { return e.student_count.teacher })
        },
        ]
      }
      setChartPlotData(value)
      setCount(count + 1)
    }
    if (chartBarData && userRole === "2") {
      Logger("VALUE ", chartBarData);
      const value = [{
        name: 'Student',
        type: 'bar',
        fill: 'solid',
        data: [chartBarData.student_count]
      }]
      Logger("VALUE >>>>>>>", value);
      setChartPlotData(value)
      setCount(count + 1)
    }
    if (chartBarData && userRole === "1") {
      const value = [
        {
          name: 'Students',
          type: 'bar',
          fill: 'solid',
          data: chartBarData && chartBarData.map((e) => { return e.student_count }),
        },
        {
          name: 'Teachers',
          type: 'bar',
          fill: 'solid',
          data: chartBarData && chartBarData.map((e) => { return e.teacher_count })
        },
      ]
      Logger("VLUE >>>", value);
      setChartPlotData(value)
      setCount(count + 1)
    }

  }

  useEffect(() => {
    if (userRole !== '2') {
      if (selectedIndex !== null) {

        Logger("I am Selected Index>>>>>>>>>", selectedIndex)
        const data = chartBarData ? JSON.stringify(chartBarData?.[selectedIndex]) : ""
        const path = '/partner';
        navigate(`/dashboard/partner?data=${encodeURIComponent(data)}`);
        Logger("I am Selected Index data>>>>>>>>>", data)
      }
    }
    if (userRole === '2') {
      if (selectedIndex !== null) {
        navigate('/dashboard/student')
      }
    }

  }, [selectedIndex])

  const chartOptions = useChart({
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          // this will print mango, apple etc. when clicked on respective datapoint
          setSelectedIndex(config.dataPointIndex)
          Logger(config.dataPointIndex, config.w.config.labels[config.dataPointIndex])
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: 85, borderRadius: 0.5, horizontal: userRole === "2" ?? true
      }
    },
    fill: { type: chartPlotData.map((i) => i.fill) },
    labels: chartBarLabel,
    xaxis: { type: 'string' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });


  return (
    <Card {...other}>
      <Box sx={{
        display: 'flex',
        justifyContent: "space-between"
      }}>
        <CardHeader title={"Statistics Report"} subheader={subheader} />
        {userRole === '0' && <Box sx={{
          padding: 2
        }}>
          <Select sx={{ width: "250px" }}
            value={partnerID}
            onChange={(event) => {
              setChartBarLabel([])
              setPartnerID(event.target.value)
            }}>
            <MenuItem value={0}>-Top 20 partners-</MenuItem>
            {
              partner && partner.map((e) => {
                return <MenuItem value={e.user_id}>{e.user_name}</MenuItem>
              })
            }
          </Select>
        </Box>}
      </Box>


      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {chartBarLabel && chartBarLabel.length !== 0 ?
          <ReactApexChart
            type="line"
            key={count}
            series={chartPlotData}
            options={chartOptions}
            height={364}
          /> : <Box display={"flex"} justifyContent={"center"} alignItems={'center'} height={364}><CircularProgress /></Box>}
      </Box>
    </Card>
  );
}
