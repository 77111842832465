import { Box, Button, Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { useState } from "react";
import { appApi } from "src/apiUtils/AppApi";
import { url } from "src/apiUtils/apiPath";
import { ToastContainer, toast } from 'react-toastify';
import Logger from "src/logger/logger";



function Feedback() {
    const [value, setValue] = useState('Suggestion')
    const [datas, setDatas] = useState({

        Email: '',
        Message: ''
    })
    const [errors, setErrors] = useState({})
    const handleFormSubmit = () => {
        const data = {
            Subject: value, ...datas
        }
        Logger("data>>>>>>>>>>>", data);

        if (validateForm())
            Logger("API call");
        appApi(url.SEND_MAIL, "POST", { 'Content-Type': 'application/json' }, JSON.stringify(data)).then((result) => {
            Logger("FEEDBACK", result);
            if (result.status === true) {
                setDatas({
                    Email: '',
                    Message: ''
                })
                notifySuccess("Email Sent Successfully")
            }
        })

    }
    const validateForm = () => {
        let isValid = true;
        // let isPhoneValid = true;
        const errors = {};


        if (!datas.Email || !/\S+@\S+\.\S+/.test(datas.Email)) {
            errors.Email = 'Please enter a valid email address';
            isValid = false;
            Logger("email>>>>>>>>", datas.Email);
        }
        if (!datas.Message || datas.Message.trim() === '') {
            errors.Message = 'Message is required';

        }



        setErrors(errors); // Set errors in state
        return errors.Email && errors.Message && errors.value
    }
    const handleSubjectOnChange = (event) => {
        Logger("handleSubjectOnChangeEvent>>>>>>", event);
        setValue(event.target.value)
    }


    const notifySuccess = (msg) => toast.success(
        msg,
        {
            width: "10%",
            pauseOnHover: false,
            autoClose: 4000,
            position: 'bottom-center'
        }
    );

    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'white'
        }}>
            <Box display={'flex'} marginTop={4}>
                <Box >
                    <img src='assets/feedback/Group 611.svg' alt="img" />
                </Box>
                <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box  >
                        <Typography variant="h3" textAlign={'center'}>Feedback</Typography>
                        <Typography color={'background.main'}>We Are Happy To Take Your Feedback</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: '100%',
                width: '100%',
                // display: 'flex'
            }} >
                <Grid container
                    sx={{
                        height: '100%',
                        width: '100%',
                    }}
                    justifyContent={'space-around'}
                    alignItems={'center'}
                    flexDirection={{
                        lg: 'row',
                        md: 'row',
                        sm: 'column-reverse',
                        xs: 'column-reverse'
                    }}
                >
                    <Grid item xs={8} sx={{
                        height: '100%',
                        width: '100%',
                    }}>
                        <Box display={'flex'} sx={{
                            height: '100%',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} >
                            <Stack sx={{ height: { lg: '80%', md: '80%', sm: '100%', xs: '100%' }, width: { lg: '60%', md: '60%', sm: '80%', xs: '80%' } }} justifyContent={'space-around'} >
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} >
                                    <Typography>Please Select a Subject</Typography>
                                    <Select
                                        sx={{
                                            bgcolor: '#F8F8F8',
                                            width: '40%',
                                            height: '60%'
                                        }}
                                        value={value}
                                        IconComponent={ExpandCircleDownOutlinedIcon}
                                        onChange={(event) => { handleSubjectOnChange(event) }}
                                    >
                                        <MenuItem value={'Suggestion'}>Suggestion</MenuItem>
                                        <MenuItem value={'Compliment'}>Compliment</MenuItem>
                                        <MenuItem value={'Bug'}>Bug</MenuItem>
                                        <MenuItem value={'Question'}>Question</MenuItem>
                                    </Select>
                                </Stack>
                                <Typography>E-mail (might be used once for follow-up)</Typography>
                                <TextField
                                    value={datas.Email}
                                    onChange={(e) => setDatas({ ...datas, Email: e.target.value })}
                                    error={errors.Email}
                                // helperText={errors.Email}
                                />
                                {errors.Email && (
                                    <Typography variant="body1" color="error">
                                        {errors.Email}
                                    </Typography>
                                )}
                                <Typography>What would you like to share with us?</Typography>
                                <TextField multiline rows={3}
                                    value={datas.Message}
                                    onChange={(e) => setDatas({ ...datas, Message: e.target.value })}
                                    error={errors.Message}
                                // helperText={errors.Email}
                                />
                                {errors.Message && (
                                    <Typography variant="body1" color="error">
                                        {errors.Message}
                                    </Typography>
                                )}
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: '#F8B239',
                                        width: '30%',
                                        boxShadow: 'none'
                                    }}
                                    onClick={handleFormSubmit}
                                >
                                    Submit
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{
                        height: '100%',
                        width: '100%',
                    }} >
                        <Box display={'flex'} sx={{
                            height: '100%',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'end',
                        }} >
                            {/* <Box >
                                <img src='assets/feedback/4.svg' alt="img" />
                            </Box> */}
                            <Box alignSelf={'center'} width={{
                                lg: '100%',
                                md: '100%',
                                sm: '100%',
                                xs: '100%'
                            }} >
                                <img src='assets/feedback/feedback.svg' alt="img" />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <ToastContainer />
        </Box >
    )
}

export default Feedback