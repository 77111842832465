import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { appApi } from "src/apiUtils/AppApi";
import { baseUrlfile, url } from "src/apiUtils/apiPath";
// import Img from 'src/_mock/Group 620.png'

export default function Gallery() {
    const [imgList, setImgList] = useState([])

    const getAllGallery = () => {
        appApi(url.GET_ALL_GALLERY_IMAGE, "GET", {}).then((result) => {
            if (result && result.status === true) {
                setImgList(result.entity)
            }
        })
    }

    useEffect(() => {
        getAllGallery()
    },[])

    return (
        <Box
            sx={{

                // height: '100vh',
                // width: '100%',
                display: 'flex',
                flexDirection: 'column',
                bgcolor: 'white',
                // overflow:'scroll'
            }}
        >
            <Box display={'flex'} marginTop={4} marginBottom={4} >
                <Box>
                    <img src='assets/feedback/Group 611.svg' alt="img" />
                </Box>
                <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box>
                        <Typography variant="h3" textAlign={'center'}>
                            Gallery
                        </Typography>
                        <Typography color={'background.main'}>Explore Our Visual Story</Typography>
                    </Box>
                </Box>
            </Box>
            {/* <Box display={'flex'}

                sx={{
                    height: { lg: '100%' },
                    width: { lg: '100%' },
                    bgcolor: { lg: 'blue', md: 'red', sm: 'green', xs: 'yellow' }

                }}
            >

                <ImageList
                    gap={20}
                    cols={3}
                    spacing={4}

                    sx={{
                        // display:{sm:'flex',xs:'flex'},
                        flexDirection: {

                            sm: 'column',
                            xs: 'column'
                        }
                    }}
                    style={{
                        width: '100%',
                        height: '60%',
                        overflow: 'hidden',
                        // backgroundColor: 'blue',
                        '@media (min-width: 1280px)': {
                            width: '70%',
                            height: '70%',


                        },
                    }}
                >
                    {itemData.map((item) => (
                        <ImageListItem
                            key={item.img}

                            // width={"40px"}
                            // height={"50px"}

                            sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                alt={item.title}
                                style={{ objectFit: 'fill', height: '350px', width: '620px' }}
                                loading="lazy"
                            />

                        </ImageListItem>
                    ))}
                </ImageList>

            </Box> */}
            <Box>
                <Grid container rowSpacing={3} columnSpacing={4} justifyContent={"space-evenly"} padding={5}>
                    {
                        imgList.map((e, i) => {
                            return <Grid item lg={3.5} md={6} sm={6} xs={12} sx={{ boxShadow: "1 1 1 1" }}  >
                                <img
                                    src={`${baseUrlfile}${e.image_url}`} 
                                    alt={i} 
                                    height={300} 
                                    width={'100%'}
                                    style={{
                                        objectFit: 'fill',
                                        objectPosition: 'center'
                                    }} />
                            </Grid>
                        })
                    }
                </Grid>
            </Box>
        </Box>
    );
}