// import React from 'react';
// import { Route, Navigate } from 'react-router-dom';

import { Navigate } from "react-router-dom"
import Logger from "src/logger/logger";

// export default function AuthRoute({ isAuthenticated, ...props }) {
//   return isAuthenticated ? <Route {...props} /> : <Navigate to="/login" />;
// }

export const AuthuProtectedRoute = ({ children }) => {
  const values = localStorage.getItem('userLoginDetail')
  Logger("Value of isAuthenticated----->", localStorage.getItem('userLoginDetail'));

  if (values && values !== "") {
    return children
    // return <Navigate to="/login"/>
  } else {
    return <Navigate to="/login" />
    //    return children;
  }
}