import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container } from '@mui/material';
import Logger from "src/logger/logger";
import { url } from 'src/apiUtils/apiPath';
import { appApi } from 'src/apiUtils/AppApi';
import { useEffect, useState } from 'react';
// components
// import Iconify from '../components/iconify';
// sections
import {
  // AppTasks,
  // AppNewsUpdate,
  // AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  // AppTrafficBySite,
  AppWidgetSummary,
  // AppCurrentSubject,
  // AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [userDtl, setUserDtl] = useState(JSON.parse(localStorage.getItem("userLoginDetail")))
  const [dashCard, setDashCard] = useState()
  const [chartLabels, setChartLabels] = useState([
    'Center A',
    'Center B',
    'Center C',
    'Center D',
    'Center E',
    'Center F',
    'Center G',
    'Center H',
    'Center I',
    'Center J',
    'Center K',
    'Center L',
    'Center M',
    'Center N',
    'Center O',
    'Center P',
    'Center Q',
    'Center R',
    'Center S',
    'Center T'
  ])
  const [chartData, setChartData] = useState([
    {
      name: 'Teachers',
      type: 'bar',
      fill: 'solid',
      data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 5, 7, 43, 65, 8, 0, 9, 42, 11],
    },
    {
      name: 'Students',
      type: 'bar',
      fill: 'solid',
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 9, 8, 21, 33, 43, 3, 2, 1, 0],
    },
    // {
    //   name: 'Courses',
    //   type: 'bar',
    //   fill: 'solid',
    //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 9, 8, 21, 33, 43, 3, 2, 1, 0],
    // },
  ])

  const adminDashboardCard = () => {
    const data = {
      "user_id": userDtl?.user?.id,
      "user_role": userDtl?.user?.role.user_role,
      "center_id": userDtl?.user?.user_center_detail?.center_id
    }
    appApi(url.ADMIN_DASH_CARD, "POST", { 'Content-Type': 'application/json' }, JSON.stringify(data)).then((result) => {
      Logger("ADMIN_DASH_CARD >>>>>>>", result, JSON.stringify(data), userDtl);
      if (result.status === true) {
        Logger("REsult>>>>>>>", result.entity);
        setDashCard(result.entity)
      }
    })
  }

  useEffect(() => {
    adminDashboardCard()
  }, [])

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        {/* <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography> */}

        <Grid container spacing={3}>
          {
            userDtl?.user.role.user_role === '0' ?
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <AppWidgetSummary title="Centers" total={dashCard?.center_count ? parseInt(dashCard?.center_count, 10) : 0} icon={'mingcute:school-line'} />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <AppWidgetSummary title="Partners" total={dashCard?.partner_count ? parseInt(dashCard?.partner_count, 10) : 0} color="info" icon={'mdi:handshake-outline'} />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <AppWidgetSummary title="Teachers" total={dashCard?.teacher_count ? parseInt(dashCard?.teacher_count, 10) : 0} color="warning" icon={'mdi:human-male-board-poll'} />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <AppWidgetSummary title="Students" total={dashCard?.student_count ? parseInt(dashCard?.student_count, 10) : 0} color="error" icon={'mdi:account-school'} />
                </Grid>
              </>
              :
              userDtl?.user.role.user_role === '1' ?
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Centers" total={dashCard?.center_count ? parseInt(dashCard?.center_count, 10) : 0} icon={'mingcute:school-line'} />
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Partners" total={dashCard?.partner_count ? parseInt(dashCard?.partner_count, 10) : 0} color="info" icon={'mdi:handshake-outline'} />
                  </Grid> */}

                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Teachers" total={dashCard?.teacher_count ? parseInt(dashCard?.teacher_count, 10) : 0} color="warning" icon={'mdi:human-male-board-poll'} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Students" total={dashCard?.student_count ? parseInt(dashCard?.student_count, 10) : 0} color="error" icon={'mdi:account-school'} />
                  </Grid>
                </>
                :
                userDtl?.user.role.user_role === '2' ?
                  <>
                    {/* <Grid item xs={12} sm={6} md={3}>
                      <AppWidgetSummary title="Centers" total={dashCard?.center_count ? parseInt(dashCard?.center_count, 10) : 0} icon={'mingcute:school-line'} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <AppWidgetSummary title="Partners" total={dashCard?.partner_count ? parseInt(dashCard?.partner_count, 10) : 0} color="info" icon={'mdi:handshake-outline'} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <AppWidgetSummary title="Teachers" total={dashCard?.teacher_count ? parseInt(dashCard?.teacher_count, 10) : 0} color="warning" icon={'mdi:human-male-board-poll'} />
                    </Grid> */}

                    <Grid item xs={12} sm={12} md={12}>
                      <AppWidgetSummary title="Students" total={dashCard?.student_count ? parseInt(dashCard?.student_count, 10) : 0} color="error" icon={'mdi:account-school'} />
                    </Grid>
                  </>
                  : ""

          }

          <Grid item xs={12} md={12} lg={12}>
            <AppWebsiteVisits
              // title="Center Statistics"
              // // subheader="(+43%) than last year"
              // chartLabels={chartLabels}
              // chartData={chartData}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Student Strength"
              chartData={[
                { label: 'Center A', value: 4344 },
                { label: 'Center B', value: 5435 },
                { label: 'Center C', value: 1443 },
                { label: 'Center D', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
