import { AccountCircle, LockResetOutlined, } from "@mui/icons-material"
import { Box, Button, Grid, IconButton, Input, InputAdornment, Stack, Typography } from "@mui/material"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appApi } from "src/apiUtils/AppApi";
import { url } from "src/apiUtils/apiPath";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logger from "src/logger/logger";

function Login() {
    const myRefs = useRef([]);
    const navigate = useNavigate()
    const [eyeIcon, setEyeIcon] = useState(false)
    const [userLoginDtl, setUserLoginDtl] = useState({
        user_login_id: null,
        password: null
    })
    // const [autoFoucs, setAutoFoucs] = useState(1)
    const loginApi = () => {
        const data = userLoginDtl
        appApi(url.USER_LOGIN, "POST", { 'Content-Type': 'application/json' }, JSON.stringify(data)).then((result) => {
            Logger("USER_LOGIN", result);
            if (result && result.status === true) {
                localStorage.setItem("userLoginDetail", JSON.stringify(result.entity))
                const role = result?.entity?.user?.role?.user_role
                localStorage.setItem("role", role)
                Logger("role", role);
                if (role === '0') {
                    navigate('/dashboard/admin')
                } else if (role === '1') {
                    navigate('/dashboard/partner/main')
                }
                else if (role === '2') {
                    navigate('/dashboard/teacher/main')
                }

            }
            else {
                notify(result.message)
            }
        })
    }
    const handleKeyUp = (e, targetElem) => {
        if (e.key === "Enter" && targetElem) {
            targetElem.focus();
        }
    };
    const notify = (msg) => toast.error(
        msg,
        {
            width: "10%",
            pauseOnHover: false,
            autoClose: 4000,
            position: 'bottom-center'
        }
    );
    // const parentBox = {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     height: '100%',
    //     width: '100%',
    //     // backgroundImage: { sm: "url('assets/login/login_bg.svg')", xs: "none", lg: "url('assets/login/login_bg.svg')", md: "url('assets/login/login_bg.svg')" },
    //     // bgcolor: 'white',
    //     // backgroundSize: 'cover',
    //     // backgroundRepeat: 'no-repeat',
    //     // backgroundPosition: 'left center',
    //     alignItems: 'center',

    // }
    return (
        // <Box sx={parentBox} >

        //     {/* <Box sx={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', bgcolor: 'red' }}> */}
        //     <Box width={'100%'} display={'flex'} justifyContent={'end'} alignItems={'center'}>
        //         <Box width={{ lg: '35%', md: '35%', sm: "50%", xs: "100%" }} justifyContent={{ sm: "end", lg: "center", md: "end", xs: "end" }} alignItems={{ sm: "end", lg: "center", md: "end", xs: "end" }} padding={{ xs: 5, sm: 5, lg: "none", md: 0 }} >
        //             <Stack spacing={3} width={{ lg: '60%', md: "70%", sm: "80%", xs: "100%" }}  >
        //                 <Stack alignItems={'center'} borderRadius={"10px"} width={{ sm: "100%", md: "100%", lg: "100%", xs: "100%" }} boxShadow={5} onClick={() => { navigate("/landing") }}>
        //                     <img src='assets/landing/hp_enterprise_logo.webp' alt='Logo' width={"100%"} />
        //                 </Stack>
        //                 <Stack alignItems={'center'} >
        //                     <Typography fontWeight={'bold'} color={'common.black'} variant="h3">Login</Typography>
        //                 </Stack>
        //                 {/* <Stack spacing={4} > */}
        //                 <Stack >
        //                     <Typography fontWeight={'bold'} variant="h5">Username</Typography>
        //                     <Input
        //                         fullWidth={'true'}
        //                         justifyContent='center'
        //                         placeholder="Enter Username"
        //                         startAdornment={
        //                             <InputAdornment>
        //                                 <AccountCircle style={{ margin: '3px' }} />
        //                             </InputAdornment>
        //                         }
        //                         style={{
        //                             backgroundColor: '#F6F6F6',
        //                             height: '60px',
        //                             padding: "5px"
        //                         }}
        //                         autoFocus={false}
        //                         disableUnderline={'true'}
        //                         onKeyUp={(e) => { handleKeyUp(e, myRefs.current[2]) }
        //                         }
        //                         inputRef={(el) => { myRefs.current[1] = el }}
        //                         onChange={(event) => {
        //                             setUserLoginDtl((prevUserLoginDtl) => ({
        //                                 ...prevUserLoginDtl,
        //                                 user_login_id: event.target.value
        //                             }));
        //                         }}
        //                     />
        //                 </Stack>
        //                 <Stack >
        //                     <Typography fontWeight={'bold'} variant="h5">Password</Typography>
        //                     <Input
        //                         onKeyUp={(e) => {
        //                             if (e.key === "Enter") {
        //                                 loginApi()
        //                             }
        //                         }
        //                         }
        //                         inputRef={(el) => { myRefs.current[2] = el }}
        //                         justifyContent='center'
        //                         style={{
        //                             backgroundColor: '#F6F6F6',
        //                             height: '60px',
        //                             padding: "5px"
        //                         }}
        //                         placeholder="Enter Password"
        //                         disableUnderline={'true'}
        //                         startAdornment={
        //                             <InputAdornment style={{ margin: '3px' }}>
        //                                 <LockResetOutlined />
        //                             </InputAdornment>

        //                         }
        //                         endAdornment={
        //                             <InputAdornment>
        //                                 {eyeIcon ?
        //                                     <IconButton
        //                                         onClick={() => {
        //                                             setEyeIcon(!eyeIcon)
        //                                         }} >
        //                                         <VisibilityIcon />
        //                                     </IconButton> :
        //                                     <IconButton
        //                                         onClick={() => {
        //                                             setEyeIcon(!eyeIcon)
        //                                         }} >
        //                                         <VisibilityOffIcon />
        //                                     </IconButton>}
        //                             </InputAdornment>
        //                         }
        //                         // autoFocus={false}
        //                         type={eyeIcon ? "text" : "password"}
        //                         onChange={(event) => {
        //                             setUserLoginDtl((prevUserLoginDtl) => ({
        //                                 ...prevUserLoginDtl,
        //                                 password: event.target.value
        //                             }));
        //                         }}
        //                     />
        //                     {/* </Stack> */}
        //                 </Stack>
        //                 <Button
        //                     variant="contained"
        //                     style={{
        //                         backgroundColor: '#F8B239'
        //                     }}
        //                     onClick={() => {
        //                         loginApi()
        //                     }}
        //                 >
        //                     Submit
        //                 </Button>

        //             </Stack>
        //         </Box>

        //     </Box>
        //     <ToastContainer />
        // </Box >
        // <Box>
        //     <Grid container sx={{
        //         display: 'flex',
        //         height: '100%',
        //         width: '100%',
        //         direction: {
        //             lg: 'row',
        //             md: 'row',
        //             sm: 'colum-reverse',
        //             xs: 'column-reverse'
        //         },
        //         bgcolor: 'white'
        //     }}>
        //         <Grid item xs={6}
        //             display={{
        //                 lg: 'flex',
        //                 md: 'flex',
        //                 sm: 'none',
        //                 xs: 'none'
        //             }}
        //             sx={{
        //                 height: '100vh',
        //                 width: '100%'
        //             }}
        //         >
        //             <Box sx={{
        //                 objectFit: 'cover',
        //                 backgroundImage: `url("assets/landing/Rectangle 749.svg")`,
        //                 backgroundRepeat: 'no-repeat',
        //                 width: '100%',
        //             }}>
        //                 {/* <img src="assets/landing/Rectangle 749.svg" alt="login_img" height={'100%'} width={'100%'} /> */}
        //             </Box>
        //         </Grid>
        //         <Grid item
        //             lg={6}
        //             md={6}
        //             sm={12}
        //             xs={12}
        //             display={'flex'}
        //             sx={{
        //                 height: '100vh',
        //                 width: '100%'
        //             }}>
        //             <Box sx={{
        //                 display: 'flex',
        //                 flexDirection: 'column',
        //                 height: '100%',
        //                 width: '100%',
        //                 alignItems: 'center',
        //                 justifyContent: 'center',
        //             }}>
        //                 <Stack
        //                     sx={{
        //                         display: 'flex',
        //                         height: '20%',
        //                         width: '100%',
        //                         justifyContent: 'center',
        //                         alignItems: 'flex-end',
        //                     }}>
        //                     <img src="assets/landing/Group 636 (1).svg" alt="landing_img"
        //                         style={{
        //                             alignSelf: 'flex-end',
        //                             height: '100%',
        //                         }} />
        //                 </Stack>
        //                 <Stack
        //                     sx={{
        //                         height: '100%',
        //                         width: {
        //                             lg: '50%',
        //                             md: '60%',
        //                             sm: '80%',
        //                             xs: '80%'
        //                         },
        //                         alignItems: 'center',
        //                         justifyContent: 'space-evenly',

        //                     }}>
        //                     <Box sx={{
        //                         display: 'flex',
        //                         height: '20%',
        //                         width: '60%',
        //                         alignItems: 'center',
        //                         justifyContent: 'center',
        //                         borderRadius: '20px',
        //                         boxShadow: 10
        //                     }} >
        //                         <img src="assets/landing/hp_enterprise_logo.webp" alt="hp_logo" style={{
        //                             borderRadius: '20px',
        //                             boxShadow: 10
        //                         }} />
        //                     </Box>
        //                     <Typography fontWeight={'bold'} color={'common.black'} variant="h3">Login</Typography>
        //                     <Stack sx={{
        //                         width: '100%'
        //                     }}>
        //                         <Typography fontWeight={'bold'} variant="h5">Username</Typography>
        //                         <Input
        //                             fullWidth
        //                             justifyContent='center'
        //                             placeholder="Enter Username"
        //                             startAdornment={
        //                                 <InputAdornment>
        //                                     <AccountCircle style={{ margin: '3px' }} />
        //                                 </InputAdornment>
        //                             }
        //                             style={{
        //                                 backgroundColor: '#F6F6F6',
        //                                 borderRadius: 10,
        //                                 height: '60px',
        //                                 padding: "5px"
        //                             }}
        //                             autoFocus={false}
        //                             disableUnderline={'true'}
        //                             onKeyUp={(e) => { handleKeyUp(e, myRefs.current[2]) }
        //                             }
        //                             inputRef={(el) => { myRefs.current[1] = el }}
        //                             onChange={(event) => {
        //                                 setUserLoginDtl((prevUserLoginDtl) => ({
        //                                     ...prevUserLoginDtl,
        //                                     user_login_id: event.target.value
        //                                 }));
        //                             }}
        //                         />
        //                     </Stack>
        //                     <Stack sx={{
        //                         width: '100%'
        //                     }}>
        //                         <Typography fontWeight={'bold'} variant="h5">Password</Typography>
        //                         <Input
        //                             fullWidth
        //                             onKeyUp={(e) => {
        //                                 if (e.key === "Enter") {
        //                                     loginApi()
        //                                 }
        //                             }
        //                             }
        //                             inputRef={(el) => { myRefs.current[2] = el }}
        //                             justifyContent='center'
        //                             style={{
        //                                 backgroundColor: '#F6F6F6',
        //                                 height: '60px',
        //                                 padding: "5px",
        //                                 borderRadius: 10
        //                             }}
        //                             placeholder="Enter Password"
        //                             disableUnderline={'true'}
        //                             startAdornment={
        //                                 <InputAdornment style={{ margin: '3px' }}>
        //                                     <LockResetOutlined />
        //                                 </InputAdornment>

        //                             }
        //                             endAdornment={
        //                                 <InputAdornment>
        //                                     {eyeIcon ?
        //                                         <IconButton
        //                                             onClick={() => {
        //                                                 setEyeIcon(!eyeIcon)
        //                                             }} >
        //                                             <VisibilityIcon />
        //                                         </IconButton> :
        //                                         <IconButton
        //                                             onClick={() => {
        //                                                 setEyeIcon(!eyeIcon)
        //                                             }} >
        //                                             <VisibilityOffIcon />
        //                                         </IconButton>}
        //                                 </InputAdornment>
        //                             }
        //                             // autoFocus={false}
        //                             type={eyeIcon ? "text" : "password"}
        //                             onChange={(event) => {
        //                                 setUserLoginDtl((prevUserLoginDtl) => ({
        //                                     ...prevUserLoginDtl,
        //                                     password: event.target.value
        //                                 }));
        //                             }}
        //                         />
        //                         {/* </Stack> */}
        //                     </Stack>
        //                     <Button
        //                         fullWidth
        //                         variant="contained"
        //                         style={{
        //                             backgroundColor: '#F8B239'
        //                         }}
        //                         onClick={() => {
        //                             loginApi()
        //                         }}
        //                     >
        //                         Submit
        //                     </Button>
        //                 </Stack>
        //                 <Stack
        //                     sx={{
        //                         display: 'flex',
        //                         height: '20%',
        //                         width: '100%',
        //                         justifyContent: 'center',
        //                         alignItems: 'start',
        //                         position: "relative",
        //                         right: {
        //                             lg: 45,
        //                             md: 10
        //                         }
        //                     }}>
        //                     <img src="assets/landing/Group 627.svg" alt="landing_img"
        //                         style={{
        //                             alignSelf: 'start',
        //                             height: '100%',
        //                         }} />
        //                 </Stack>
        //             </Box>
        //         </Grid>
        //     </Grid>
        // </Box >
        <Box
            sx={{
                height: '100%',
                width: '100%',
                backgroundImage: `url("assets/landing/Group 7466.svg")`,
                backgroundRepeat: 'no-repeat',
                backgroundPositon: 'center center',
                backgroundSize: 'cover'
            }}>
            <Grid container
                sx={{
                    height: '100%',
                    width: '100%'
                }}
            >
                <Grid item xl={6} lg={6} md={6}
                    sx={{
                        display: {
                            xl: 'flex',
                            lg: 'flex',
                            md: 'flex',
                            sm: 'none',
                            xs: 'none'
                        },
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <img src='assets/landing/HPE_BIG 1.svg' alt='logo' />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Box
                            sx={{
                                height: '20%',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end'
                            }}>
                            <img src="assets/landing/Group 635.svg" alt="element_1"
                                style={{
                                    height: '80%',
                                }} />
                        </Box>
                        <Box
                            sx={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}>

                            <Box sx={{
                                height: '80%',
                                width: {
                                    xl: '50%',
                                    lg: '50%',
                                    md: '60%',
                                    sm: '80%',
                                    xs: '80%'
                                },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '10px',
                                backgroundImage:
                                    'linear-gradient(180.03deg, rgba(255, 255, 255, 0.74) 0.03%, rgba(150, 150, 150, 0) 133.51%)',
                            }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '85%',
                                        width: '90%',
                                        alignItems: 'center',
                                        justifyContent: 'space-evenly'
                                    }}>
                                    <Typography fontWeight={'bold'} color={'common.white'} variant="h3" textAlign={'center'}>Login</Typography>
                                    <Stack sx={{
                                        width: '100%'
                                    }}>
                                        <Typography fontWeight={'bold'} variant="h5" color={'common.white'}>Username</Typography>
                                        <Input
                                            fullWidth
                                            justifyContent='center'
                                            placeholder="Enter Username"
                                            startAdornment={
                                                <InputAdornment>
                                                    <AccountCircle style={{ margin: '3px' }} />
                                                </InputAdornment>
                                            }
                                            style={{
                                                backgroundColor: '#F6F6F6',
                                                borderRadius: 10,
                                                height: '60px',
                                                padding: "5px"
                                            }}
                                            autoFocus={false}
                                            disableUnderline={'true'}
                                            onKeyUp={(e) => { handleKeyUp(e, myRefs.current[2]) }
                                            }
                                            inputRef={(el) => { myRefs.current[1] = el }}
                                            onChange={(event) => {
                                                setUserLoginDtl((prevUserLoginDtl) => ({
                                                    ...prevUserLoginDtl,
                                                    user_login_id: event.target.value
                                                }));
                                            }}
                                        />
                                    </Stack>
                                    <Stack sx={{
                                        width: '100%'
                                    }}>
                                        <Typography fontWeight={'bold'} variant="h5" color={'common.white'}>Password</Typography>
                                        <Input
                                            fullWidth
                                            onKeyUp={(e) => {
                                                if (e.key === "Enter") {
                                                    loginApi()
                                                }
                                            }
                                            }
                                            inputRef={(el) => { myRefs.current[2] = el }}
                                            justifyContent='center'
                                            style={{
                                                backgroundColor: '#F6F6F6',
                                                height: '60px',
                                                padding: "5px",
                                                borderRadius: 10
                                            }}
                                            placeholder="Enter Password"
                                            disableUnderline={'true'}
                                            startAdornment={
                                                <InputAdornment style={{ margin: '3px' }}>
                                                    <LockResetOutlined />
                                                </InputAdornment>

                                            }
                                            endAdornment={
                                                <InputAdornment>
                                                    {eyeIcon ?
                                                        <IconButton
                                                            onClick={() => {
                                                                setEyeIcon(!eyeIcon)
                                                            }} >
                                                            <VisibilityIcon />
                                                        </IconButton> :
                                                        <IconButton
                                                            onClick={() => {
                                                                setEyeIcon(!eyeIcon)
                                                            }} >
                                                            <VisibilityOffIcon />
                                                        </IconButton>}
                                                </InputAdornment>
                                            }
                                            // autoFocus={false}
                                            type={eyeIcon ? "text" : "password"}
                                            onChange={(event) => {
                                                setUserLoginDtl((prevUserLoginDtl) => ({
                                                    ...prevUserLoginDtl,
                                                    password: event.target.value
                                                }));
                                            }}
                                        />
                                        {/* </Stack> */}
                                    </Stack>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        style={{
                                            backgroundColor: '#F8B239'
                                        }}
                                        onClick={() => {
                                            loginApi()
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <ToastContainer />
        </Box >
    )
}

export default Login