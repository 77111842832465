import { Avatar, Box, Button, Grid, Paper, Typography } from "@mui/material"
import { mt } from "date-fns/locale"
import HorizontalScroll from "src/pages/scrollimage"

function Ours() {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            // justifyContent: "space-evenly",
        }}>
            <Box position={"relative"} width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                <Box display={"flex"} justifyContent={"end"} marginTop={7} height={"30%"} >
                    <Box width={"20%"} marginTop={2} display={"flex"} justifyContent={"end"} >
                        <img src="/assets/landing/Group 636.png" alt="" />
                    </Box>
                </Box>
                <Box display={"flex"} justifyContent={"start"} marginBottom={7} height={"50%"} >
                    <Box width={"20%"} marginTop={2} display={"flex"} justifyContent={"start"} alignItems={"end"} >
                        <img src="/assets/landing/Group 627.svg" alt="" />
                    </Box>
                </Box>

                <Box position={"absolute"} height={"100%"} width={"100%"}
                    display={"flex"} flexDirection={"column"}>
                    <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"space-around"}>
                        <Box height={"100%"} width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-around"}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    flexDirection: "column"
                                }}
                            >
                                <Typography variant="h2" >
                                    What Our Students Say
                                </Typography>
                                <Typography variant="h4" color={"text.main"}>
                                    HPE Digital Classrooms : Stage To Excel
                                </Typography>
                            </Box>
                            <Box height={{ lg: "50%", md: "50%", sm: "85%", xs: "80%" }} width={"100%"} display={"flex"} flexDirection={{ lg: "row", md: "row", sm: "column", xs: "column" }} alignItems="center" justifyContent={"space-around"}>
                                <Box
                                    width={{ lg: "45%", md: "45%", sm: "45%", xs: "90%" }}
                                    height={{ lg: "90%", md: "90%", sm: "45%", xs: "45%" }}
                                    bgcolor={"background.main"}
                                    display={"flex"}
                                    flexDirection={{ lg: "row", md: "row", sm: "column", xs: "column" }}
                                    justifyContent={"space-evenly"}
                                    alignItems={"center"}
                                    borderRadius={2}
                                >
                                    <Box height={{ lg: "80%", md: "80%", sm: "45%", xs: "50%" }} width={{ lg: "35%", md: "35%", sm: "45%", xs: "45%" }} >
                                        <Box width={"100%"} height={"100%"} >
                                            <Box position={"relative"} width={"100%"} height={"100%"} borderRadius={5} bgcolor={"red"}>
                                                <img src="/assets/landing/image 250.png" alt="" width={"100%"} height={"100%"} style={{ borderRadius: "10px" }} />
                                                <Box position={"absolute"} bottom={0} right={-40} bgcolor={"button.primary"} width={"50%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} borderRadius={2}>

                                                    <Typography fontSize={{ lg: "20px" }} color={"common.white"} fontWeight={5}>
                                                        SAKSHI
                                                    </Typography>

                                                    <Typography fontWeight={5}>
                                                        Students
                                                    </Typography>
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Box>
                                    <Box height={{ lg: "50%", md: "50%", sm: "40%", xs: "40%" }} width={{ lg: "45%", md: "45%", sm: "90%", xs: "90%" }} >
                                        <Typography fontSize={{ lg: "20px", md: "20px", sm: "20px", xs: "20px" }}>
                                            It's a well structured course which helps us to understand things very effectively.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    width={{ lg: "45%", md: "45%", sm: "45%", xs: "90%" }}
                                    height={{ lg: "90%", md: "90%", sm: "45%", xs: "45%" }}
                                    bgcolor={"background.main"}
                                    display={"flex"}
                                    flexDirection={{ lg: "row", md: "row", sm: "column", xs: "column" }}
                                    justifyContent={"space-evenly"}
                                    alignItems={"center"}
                                    borderRadius={2}
                                >
                                    <Box height={{ lg: "80%", md: "80%", sm: "45%", xs: "50%" }} width={{ lg: "35%", md: "35%", sm: "45%", xs: "45%" }} >
                                        <Box width={"100%"} height={"100%"} >
                                            <Box position={"relative"} width={"100%"} height={"100%"} borderRadius={5} >
                                                <img src="/assets/landing/image 249.png" alt="" width={"100%"} height={"100%"} style={{ borderRadius: "10px" }} />
                                                <Box position={"absolute"} bottom={0} right={-40} bgcolor={"button.primary"} width={"50%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} borderRadius={2}>

                                                    <Typography fontSize={{ lg: "20px" }} color={"common.white"} fontWeight={5}>
                                                        RAGUL
                                                    </Typography>

                                                    <Typography fontWeight={5}>
                                                        Students
                                                    </Typography>
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Box>
                                    <Box height={{ lg: "50%", md: "50%", sm: "40%", xs: "40%" }} width={{ lg: "45%", md: "45%", sm: "90%", xs: "90%" }} >
                                        <Typography fontSize={{ lg: "20px", md: "20px", sm: "20px", xs: "20px" }}>
                                            It's a well structured course which helps us to understand things very effectively.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box bgcolor={"yellow"} display={"flex"} justifyContent={"space-around"} flexDirection={{ sm: "column", xs: "column", lg: "row", md: "row" }} height={"100%"} width={"100%"}>

                                <Box width={"100%"} height={"100%"} bgcolor={"hotpink"} display={"flex"} >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            // marginRight: "5%",
                                            width: { sm: "100%", xs: "100%", lg: "100%", md: "100%" },
                                            height: "100%",
                                            bgcolor: "background.main",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                            // position: "relative",
                                            padding: 5,
                                            borderRadius: "10px",
                                            flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" }
                                            // alignItems: "space-around"
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                height: { lg: "90%", md: "90%", sm: "100%", xs: "100%" },

                                                borderRadius: "20px",
                                                alignItems: "end",
                                                justifyContent: "end",
                                                position: "relative",


                                            }}
                                        >
                                            <img src="/assets/landing/image 250.png" alt="" width={"100%"} height={"100%"} style={{ borderRadius: "20px" }} />
                                            <Box sx={{
                                                bgcolor: "button.primary",
                                                position: "absolute",
                                                right: -50,
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                borderRadius: "10px"
                                            }}>

                                                <Box sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    width: "100%"
                                                }}>
                                                    <Typography variant="h4" color={"common.white"} >
                                                        SAKSHI
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        width: "100%"
                                                    }}>
                                                    <Typography variant="h5"  >
                                                        student
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                        <Box width={"50%"}  >
                                            <Typography fontSize={{ lg: "20px", md: "20px", sm: "15px", xs: "10px" }} color={"common.white"}>
                                                It's a well structured course which helps us to understand things very effectively.
                                            </Typography>

                                        </Box>

                                    </Box>
                                </Box>
                                {/* <Box width={"100%"} bgcolor={"hotpink"} display={{ sm: "none", lg: "flex", md: "flex", xs: "none" }} >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            // marginRight: "5%",
                                            width: { sm: "50%", xs: "50%", lg: "100%", md: "100%" },
                                            height: "100%",
                                            // height: { lg: "100%", md: "100%", sm: "50%", xs: "50%" },
                                            // height: { lg: "50vh", sm: "100vh", xs: "100vh" },
                                            bgcolor: "background.main",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                            // position: "relative",
                                            padding: 5,
                                            borderRadius: "10px",
                                            flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" }
                                            // alignItems: "space-around"
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "40%",
                                                height: { lg: "90%", md: "90%", sm: "50%", xs: "50%" },
                                                bgcolor: "blue",
                                                // backgroundImage: "url('/assets/landing/image 250.png')",
                                                // backgroundSize: 'cover',
                                                // backgroundRepeat: 'no-repeat',
                                                borderRadius: "20px",
                                                alignItems: "end",
                                                justifyContent: "end",
                                                position: "relative",


                                            }}
                                        >
                                            <img src="/assets/landing/image 250.png" alt="" width={"100%"} height={"100%"} style={{ borderRadius: "20px" }} />
                                            <Box sx={{
                                                bgcolor: "button.primary",
                                                position: "absolute",
                                                right: -50,
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                borderRadius: "10px"
                                            }}>

                                                <Box sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    width: "100%"
                                                }}>
                                                    <Typography variant="h4" color={"common.white"} >
                                                        SAKSHI
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        width: "100%"
                                                    }}>
                                                    <Typography variant="h5"  >
                                                        student
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                        <Box width={"50%"}  >
                                            <Typography fontSize={{ lg: "20px", md: "20px", sm: "15px", xs: "10px" }} color={"common.white"}>
                                                It's a well structured course which helps us to understand things very effectively.
                                            </Typography>

                                        </Box>

                                    </Box>
                                </Box> */}

                            {/* </Box> */}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

export default Ours