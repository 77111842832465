import { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Logger from "src/logger/logger";
import { Box, Tab } from '@mui/material';
import TeacherTab from './teacherTabView';
import StudentTab from './studentTabView';


export default function CenterToStudentAndTeacher(props) {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        Logger("Props >>>>>>>>>", props);
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Teacher" value="1" sx={{ fontSize: '20px' }} />
                        <Tab label="Student" value="2" sx={{ fontSize: '20px' }} />
                    </TabList>
                </Box>
                <TabPanel value="1"><TeacherTab centerDtl={props.centerDtl} /></TabPanel>
                <TabPanel value="2"><StudentTab centerDtl={props.centerDtl} /></TabPanel>
            </TabContext>
        </Box>
    );
}