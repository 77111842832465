import About from "./about"
import Footer from "../main/footer/footer"
import DrawerAppBar from "../main/nav/mainnavbar"
import AboutTwo from "./about_two"

function AboutMain() {

    return (
        <>
            <DrawerAppBar />
            <About />
            <AboutTwo />
            <Footer />
        </>
    )
}

export default AboutMain