
import Footer from "../main/footer/footer"
import DrawerAppBar from "../main/nav/mainnavbar"
import StudentPortal from "./studentPortal"

function StudentPortalMain() {

    return (
        <>
            <DrawerAppBar />
            <StudentPortal/>
            <Footer />
        </>
    )
}

export default StudentPortalMain