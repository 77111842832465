import { Helmet } from 'react-helmet-async';
import { filter, result } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Grid,
  List,
  ListItem,
  TextField,
  Divider,
  Radio
} from '@mui/material';
// import { Radio } from '@mui/joy';
import { ArrowBack, BlockOutlined, CancelOutlined, DeleteOutline, Update } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Logger from "src/logger/logger";
import { appApi } from 'src/apiUtils/AppApi';
import { url } from 'src/apiUtils/apiPath';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import PartnerToCenter from './partnertocenter';
import CenterToStudentAndTeacher from './centertoStudentandTeacher';
// import { eventManager } from 'react-toastify/dist/core';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'contact', label: 'Contact', alignRight: false },
  { id: 'loginID', label: 'Login ID', alignRight: false },
  { id: 'password', label: 'Password', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'options', label: 'Options', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage(props) {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const passedData = searchParams.get('data');
  const userDtl = localStorage.getItem("userLoginDetail")
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);
  const [isLoading, setIsloading] = useState(false)

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('user.parnter_detail.user_name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [centerOpen, setCenterOpen] = useState(false);
  const [centerDetail, setCenterDetail] = useState([])
  const [cityDetail, setCityDetail] = useState([]);
  const [partnerList, setPartnerList] = useState([])
  const [selectedPartner, setSelectedPartner] = useState({})
  const [partnerDtl, setPartnerDtl] = useState({})
  const [redirect, setRedirect] = useState(false)
  const [centerDtl, setCenterDtl] = useState({
    'center_name': null,
    'center_id': null
  })
  const [userProfileDetail, setUserProfileDetail] = useState({
    "user_login_id": null,
    "user_role": "1",
    "password": null,
    "user_city": null,
    "center_id": null,
    "createdBy": JSON.parse(userDtl).user.id,
    "user_name": null,
    "user_gender": null,
    "user_age": null,
    "user_phone": null
  })
  const [selectedValue, setSelectedValue] = useState('');
  const [editIndex, setEditIndex] = useState(null)
  const [hidePartnerList, setHidePatnerList] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editIndexTrigger, setEditIndexTrigger] = useState(0)
  const [centerToStuAndTea, setCenterAndStuAndTea] = useState(false)
  useEffect(() => {
    appApi(url.CENTER_ALL_LIST, "POST", {}).then((result) => {
      if (result && result.status === true) {
        setCenterDetail(result.entity)
      } else {
        notify(result.message)

      }
    })
    appApi(url.CITY_ALL_LIST, "GET", {}).then((result) => {
      if (result && result.status === true) {
        setCityDetail(result.entity)
      } else {
        notify(result.message)

      }
    })
    handleGetPartnerList()
  }, [])

  useEffect(() => {
    const data = JSON.parse(passedData)
    Logger("PassedData>>>>>>>", data);
    if (data) {
      if (data.partner_id !== null && data.partner_id !== undefined) {
        Logger("Top 20>>>>>>>>>>>>");
        const partrnerData = {
          'partner_name': data.partner_name,
          'partner_id': data.partner_id
        }
        setPartnerDtl(partrnerData)
        setHidePatnerList(true)
        setCenterAndStuAndTea(false)
      }

      if (data.center_id !== null && data.center_id !== undefined) {
        Logger("Center>>>>>>>>>>>>>>");
        setCenterDtl((prev) => ({
          'center_name': data.center_name,
          'center_id': data.center_id
        }))
        setCenterAndStuAndTea(true)
        setHidePatnerList(true)
        setRedirect(true)
      }
    }
  }, [passedData])

  const notify = (msg) => toast.error(
    msg,
    {
      pauseOnHover: false,
      autoClose: 4000,
      position: 'bottom-center'
    }
  );
  const notifySuccess = (msg) => toast.success(
    msg,
    {
      pauseOnHover: false,
      autoClose: 4000,
      position: 'bottom-center'
    }
  );
  const handleModalOpen = () => {
    handleCloseMenu()
    setSelectedValue('')
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setUserProfileDetail((prev) => ({
      ...prev,
      "user_login_id": null,
      "password": null,
      "user_city": null,
      "center_id": null,
      "user_name": null,
      "user_gender": null,
      "user_age": null,
      "user_phone": null
    }))
    setModalOpen(false)
    setIsEdit(false)
  };

  useEffect(() => {
    Logger("isEdit>>>>>>>>>", isEdit);
  }, [isEdit])
  const handleCenterOpen = () => {
    setCenterOpen(true);
  };
  const handleCenterClose = () => {
    setCenterOpen(false);
  };
  const handleOpenMenu = (event, value) => {
    setSelectedPartner(value)
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = partnerList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
    Logger("filterName.length", filterName.length);
    if (filterName.length >= 3) {
      handleGetPartnerList()
    }
    if (filterName.length === 1) {
      // setFilterName('');
      handleGetPartnerList()
    }
  };

  const editPartner = (data) => {
    if (data !== undefined) {
      // const editData = {
      //   'user_login_id': data.user.user_login_id,
      //   'user_name': data.user.partner_detail.user_name,
      //   'user_phone': data.user.partner_detail.user_phone,
      //   'user_age': data.user.partner_detail.user_age,
      //   'user_gender': data.user.partner_detail.user_gender,
      //   'user_city': data.user.partner_detail.user_city
      // }
      Logger("partner user id>>>>>>>>>", data.user.id);
      setSelectedValue(data.user.partner_detail.user_gender)
      setUserProfileDetail((prev) => ({
        ...prev,
        'user_id': data.user.id,
        'user_login_id': data.user.user_login_id,
        'user_name': data.user.partner_detail.user_name,
        'user_phone': data.user.partner_detail.user_phone,
        'user_age': data.user.partner_detail.user_age,
        'user_gender': data.user.partner_detail.user_gender,
        'user_city': data.user.partner_detail.user_city,
        'user_role': '1'
      }))
    }
  }

  useEffect(() => {
    Logger("list>>>>>>>>", partnerList[editIndex], editIndex);
    editPartner(partnerList[editIndex])
  }, [editIndexTrigger])

  const handleGetPartnerList = () => {
    setIsloading(true)
    appApi(url.USERS_LIST, "POST", { 'Content-Type': 'application/json' }, JSON.stringify({ "filter_name": filterName.length === 1 ? '' : filterName, "user_role": "1", "my_user_role": localStorage.getItem('role'), "filter_user_id": JSON.parse(userDtl).user.id, offset: 1, limit: 10 }))
      .then((result) => {
        setIsloading(false)
        Logger("handleGetPartnerList", result);
        if (result && result.status === true) {
          setPartnerList(result.entity)
        }
      })
  }
  const handleGetPartnerBlock = () => {
    const data = {
      'user_id': selectedPartner.user_id,
      'status': selectedPartner.status,
      'is_deleted': null
    }
    appApi(url.USERS_BLOCK, "DELETE", { 'Content-Type': 'application/json' }, JSON.stringify(data))
      .then((result) => {
        Logger("handleGetTeacherList", result);
        if (result && result.status === true) {
          handleCloseMenu()
          handleGetPartnerList()
          notifySuccess(selectedPartner.status === true ? "Block" : "Un-Block")

        }
      })
  }

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const passedData = searchParams.get('data');
  //   Logger("Passed Data >>>>>>>>>>>>>", passedData);
  // }, [])

  const handleCreateUser = () => {
    const data = {
      ...userProfileDetail, createdBy: JSON.parse(userDtl).user.id
    }
    Logger("handle Create user", data, JSON.parse(userDtl).user.id)

    if (userProfileDetail.user_login_id !== null && userProfileDetail.user_login_id !== '' &&
      userProfileDetail.password !== null && userProfileDetail.password !== '' &&
      userProfileDetail.user_city !== null && userProfileDetail.user_city !== '') {
      appApi(url.CREATE_USERS, "POST", { 'Content-Type': 'application/json' }, JSON.stringify(userProfileDetail))
        .then((result) => {
          if (result && result.status === true) {
            handleModalClose()
            handleGetPartnerList()
            notifySuccess("Partner created successfully")
          } else {
            notify(result.message)
          }
        })
    }
    else {
      notify('Login ID,Password & City is Mandatory')
    }
  }

  const updateUser = () => {

    const data = {
      ...userProfileDetail, createdBy: JSON.parse(userDtl).user.id
    }
    Logger("handle update user", data, JSON.parse(userDtl).user.id)

    appApi(url.UPDATE_USERS, "PUT", { 'Content-Type': 'application/json' }, JSON.stringify(userProfileDetail))
      .then((result) => {
        if (result && result.status === true) {
          handleModalClose()
          handleGetPartnerList()
          notifySuccess("Partner updated successfully")
        } else {
          notify(result.message)
        }
      })
  }
  const handleBackButton = () => {
    Logger("Redirect>>>>>>>>>>>", redirect, JSON.parse(userDtl).user.role.user_role);
    if (userDtl && JSON.parse(userDtl).user.role.user_role === '0') {
      if (redirect === false) {
        if (centerToStuAndTea === true) {
          setCenterAndStuAndTea(false)
        }
        else if (centerToStuAndTea === false && hidePartnerList === true) {
          setHidePatnerList(false)
        }
      }

      if (redirect === true) {
        Logger("Redirect>>>>>>>>>>>>>>>>>", hidePartnerList, centerToStuAndTea);
        if (centerToStuAndTea === true && hidePartnerList === true) {
          setHidePatnerList(false)
          setCenterAndStuAndTea(false)
        }
      }
    }
    //   else if (userDtl && JSON.parse(userDtl).user.role.user_role === '1') {
    //     Logger("userDtl>>>>>>>>>>>",hidePartnerList,centerToStuAndTea);
    //     setHidePatnerList(true)
    //     setCenterAndStuAndTea(false)
    //   }
  }
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partnerList.length) : 0;

  // const filteredUsers = applySortFilter(partnerList, getComparator(order, orderBy), filterName);

  // const isNotFound = !filteredUsers.length && !!filterName;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    // color: "primary.mainSecond",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Helmet>
        <title> Partner </title>
      </Helmet>
      <Box >
        <IconButton onClick={() => {
          handleBackButton()
        }}>
          <ArrowBack />
        </IconButton>
      </Box>
      {hidePartnerList === false ? < Container >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Partner
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => {
            handleModalOpen()
            setUserProfileDetail((prev) => ({
              ...prev,
              "user_login_id": null,
              "password": null,
              "user_city": null,
              "center_id": null,
              "user_name": null,
              "user_gender": null,
              "user_age": null,
              "user_phone": null
            }))
          }}>
            Add Partner
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} title={"Partner"} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={partnerList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {partnerList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                    const { id, name, role, status, company, avatarUrl, isVerified } = row;

                    const selectedUser = selected.indexOf(row?.user?.partner_detail?.user_name) !== -1;

                    return (
                      <TableRow hover tabIndex={-1} role="checkbox" selected={selectedUser}>


                        <TableCell component="th" onClick={() => {
                          setPartnerDtl({
                            'partner_name': partnerList[page * rowsPerPage + i].user.partner_detail.user_name,
                            'partner_id': partnerList[page * rowsPerPage + i].user.id
                          })
                          setHidePatnerList(true)
                        }}>

                          <Typography variant="subtitle2" noWrap>
                            {row?.user?.partner_detail?.user_name || "-"}
                          </Typography>

                        </TableCell>

                        <TableCell align="left">{row?.user?.partner_detail?.user_city || "-"}</TableCell>

                        <TableCell align="left">{row?.user?.partner_detail?.user_phone || "-"}</TableCell>

                        <TableCell align="left">{row?.user?.user_login_id || "-"}</TableCell>

                        <TableCell align="left"
                        >
                          {/* <TextField
                            type={
                              isPassword ? 'text' : 'password'
                            }
                            aria-readonly={'true'}
                            value={row?.user?.id || "-"}
                            onMouseEnter={() => {
                              setIsPassword(true)
                            }}
                            onMouseLeave={() => {
                              setIsPassword(false)
                            }}
                          /> */}
                          {row?.user?.password || "-"}
                        </TableCell>

                        <TableCell align="left">
                          <Label color={(row?.user?.user_blocked === true ? 'error' : 'success')}>{sentenceCase((row?.user?.user_blocked === true ? "blocked" : "Active"))}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => {
                            handleOpenMenu(event, { user_id: row?.user?.id, status: !row?.user?.user_blocked })
                            if (page > 0) {
                              setEditIndex(page * rowsPerPage + i)
                              setEditIndexTrigger(editIndexTrigger + 1)
                              Logger("page>>>>>>", page, rowsPerPage, rowsPerPage + i);
                            }
                            else {
                              setEditIndex(i)
                              setEditIndexTrigger(editIndexTrigger + 1)
                              Logger("page>>>>>>", page, rowsPerPage, i);
                            }
                          }}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {partnerList && partnerList.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            {isLoading ? 'Loading .....' : 'No Data Found'}
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={partnerList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container > : null
      }

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => {
          setIsEdit(true)
          handleModalOpen()
        }}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }}
          />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: (selectedPartner && selectedPartner.status === true) ? "green" : 'error.main' }} onClick={handleGetPartnerBlock}>
          <Box display={"flex"} alignItems={"center"}>
            <BlockOutlined sx={{ mr: 1.5 }} />
            {selectedPartner && selectedPartner.status === true ? "Block" : "Un-Block"}
          </Box>
        </MenuItem>
      </Popover>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
      >
        <Box sx={{ ...style, height: "80%", display: "flex", overflow: "hidden", flexDirection: "column" }}>
          <Box display={"flex"} alignItems={"center"}>
            <Box flexGrow={1}>
              <Typography>
                Profile Detail
              </Typography>
            </Box>
            <Box flexGrow={0}>
              <IconButton onClick={handleModalClose}>
                <CancelOutlined />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"} overflow={"scroll"}>
            <List >
              {isEdit !== true ? <ListItem>
                <TextField
                  required
                  // aria-readonly={isEdit ? false : true}
                  label={"Login ID"}
                  name="user_login_id"
                  placeholder='Enter Login Id'
                  // variant="outlined"
                  // focused
                  value={userProfileDetail.user_login_id}
                  onChange={(event) => {
                    setUserProfileDetail((pre) => ({
                      ...pre,
                      user_login_id: event.target.value
                    }))
                  }}
                />
              </ListItem>
                : ""}
              <ListItem>
                <TextField
                  label={"Name"}
                  name="user_name"
                  // variant="outlined"
                  placeholder='Enter Username'
                  // focused
                  // autoFocus={false}
                  value={userProfileDetail.user_name}
                  onChange={(event) => {
                    setUserProfileDetail((pre) => ({
                      ...pre,
                      user_name: event.target.value
                    }))
                  }}
                />
              </ListItem>
              {isEdit === false ?
                <ListItem>
                  <TextField
                    required
                    label={"Password"}
                    name="password"
                    placeholder='Enter Password'
                    value={userProfileDetail.user_password}
                    // variant="outlined"
                    // focused
                    onChange={(event) => {
                      setUserProfileDetail((pre) => ({
                        ...pre,
                        password: event.target.value
                      }))
                    }}
                  />
                </ListItem>
                : ""}
              <ListItem>
                <TextField
                  label={"Phone"}
                  name="user_phone"
                  placeholder='Enter Phone number'
                  type='number'
                  value={userProfileDetail.user_phone}
                  // variant="outlined"
                  // focused
                  onChange={(event) => {
                    setUserProfileDetail((pre) => ({
                      ...pre,
                      user_phone: event.target.value
                    }))
                  }}
                />
              </ListItem>
              <ListItem>
                <TextField
                  label={"Age"}
                  name="user_age"
                  placeholder='Enter age'
                  type='number'
                  value={userProfileDetail.user_age}
                  // variant="outlined"
                  // focused
                  onChange={(event) => {
                    setUserProfileDetail((pre) => ({
                      ...pre,
                      user_age: event.target.value
                    }))
                  }}
                />
              </ListItem>
              <ListItem>
                <TextField
                  select
                  fullWidth
                  label={"Gender"}
                  name="user_gender"
                  value={userProfileDetail.user_gender}
                  // variant="outlined"
                  // focused
                  onChange={(event) => {
                    setUserProfileDetail((pre) => ({
                      ...pre,
                      user_gender: event.target.value
                    }))
                  }
                  }
                >
                  <MenuItem value={'Male'}>Male</MenuItem>
                  <MenuItem value={'Female'} >Female</MenuItem>
                  <MenuItem value={'Transgender'}>Transgender</MenuItem>
                </TextField>
              </ListItem>
              <ListItem>
                <TextField
                  // select
                  required
                  fullWidth
                  label={"City"}
                  name="user_city"
                  placeholder='Enter city'
                  value={userProfileDetail.user_city}
                  // variant="outlined"
                  // focused
                  onChange={(event) => {
                    setUserProfileDetail((pre) => ({
                      ...pre,
                      user_city: event.target.value
                    }))
                  }
                  }
                >
                  {/* {cityDetail && cityDetail.length !== 0 ? cityDetail.map((e, i) => {
                    return <MenuItem value={e.id} >{e.city}</MenuItem>
                  }) : <MenuItem value="">none</MenuItem>} */}
                </TextField>
              </ListItem>
              {/* <ListItem>
                <TextField
                  required
                  select
                  fullWidth
                  label={"Center"}
                  placeholder='Select center'
                  name="center_id"
                  onChange={(event) => {
                    setUserProfileDetail((pre) => ({
                      ...pre,
                      center_id: event.target.value
                    }))
                  }
                  }

                >

                  {centerDetail && centerDetail.length !== 0 ? centerDetail.map((e, i) => {
                    return <MenuItem value={e.id} >{e.center_name}</MenuItem>
                  }) : <MenuItem value="">none</MenuItem>}

                </TextField>
              </ListItem> */}






            </List>

            <Button
              onClick={() => {
                if (isEdit) {
                  updateUser()
                }
                else {
                  handleCreateUser()
                }
              }}
              sx={{
                color: "common.black",
                paddingLeft: "10%",
                paddingRight: "10%",
                "&:hover": {
                  backgroundColor: "button.primary"
                },
                bgcolor: "button.primary"
              }}>
              {isEdit ? 'Update' : 'Create'}
            </Button>


          </Box>
        </Box>
      </Modal >

      <ToastContainer />
      {
        hidePartnerList === true && centerToStuAndTea === false ? <PartnerToCenter callBack={
          (value) => {
            Logger("Values>>>>>>>>>", value);
            setCenterAndStuAndTea(value.status)
            setCenterDtl((prev) => ({
              'center_name': value.center_name,
              'center_id': value.center_id,
              'center_location': value.center_location
            }))
          }} partnerDtl={partnerDtl} /> : null
      }
      {
        centerToStuAndTea === true ? <CenterToStudentAndTeacher centerDtl={centerDtl} redirect={redirect} /> : null
      }
    </>
  );
}
