import { Helmet } from 'react-helmet-async';
// import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    // Avatar,
    Button,
    Popover,
    // Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Modal,
    Box,
    // Grid,
    List,
    ListItem,
    TextField,
    Divider,
    // Radio
} from '@mui/material';
// import { Radio } from '@mui/joy';
import { BlockOutlined, CancelOutlined } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logger from "src/logger/logger";
import { appApi } from 'src/apiUtils/AppApi';
import { url } from 'src/apiUtils/apiPath';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';
// import { eventManager } from 'react-toastify/dist/core';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'location', label: 'Location', alignRight: false },
    { id: 'contact', label: 'Contact', alignRight: false },
    { id: 'grade', label: 'Grade', alignRight: false },
    { id: 'center', label: 'Center', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'options', label: 'Options', alignRight: true },
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//     if (b[orderBy] < a[orderBy]) {
//         return -1;
//     }
//     if (b[orderBy] > a[orderBy]) {
//         return 1;
//     }
//     return 0;
// }

// function getComparator(order, orderBy) {
//     return order === 'desc'
//         ? (a, b) => descendingComparator(a, b, orderBy)
//         : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//     const stabilizedThis = array.map((el, index) => [el, index]);
//     stabilizedThis.sort((a, b) => {
//         const order = comparator(a[0], b[0]);
//         if (order !== 0) return order;
//         return a[1] - b[1];
//     });
//     if (query) {
//         return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//     }
//     return stabilizedThis.map((el) => el[0]);
// }

export default function StudentTab(props) {
    const userDtl = localStorage.getItem("userLoginDetail")
    const role = localStorage.getItem("role")
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');
    const [isLoading, setIsloading] = useState(false)

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('user.student_detail.user_name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [modalOpen, setModalOpen] = useState(false);
    const [centerOpen, setCenterOpen] = useState(false);
    const [centerDetail, setCenterDetail] = useState([])
    const [cityDetail, setCityDetail] = useState([]);
    const [studentList, setStudentList] = useState([])
    const [editIndex, setEditIndex] = useState(false)
    const [editIndexTrigger, setEditIndexTrigger] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedStudent, setSelectedStudent] = useState({})
    const [userProfileDetail, setUserProfileDetail] = useState({
        "user_login_id": null,
        "user_role": "3",
        "password": null,
        "user_city": props.centerDtl.center_location,
        "center_id": props.centerDtl.center_id,
        'user_grade': null,
        "createdBy": JSON.parse(userDtl).user.id,
        "user_name": null,
        "user_gender": null,
        "user_age": null,
        "user_phone": null
    })
    const [selectedValue, setSelectedValue] = useState('');
    useEffect(() => {
        appApi(url.CENTER_ALL_LIST, "POST", {}).then((result) => {
            if (result && result.status === true) {
                setCenterDetail(result.entity)
            } else {
                notify(result.message)

            }
        })
        appApi(url.CITY_ALL_LIST, "GET", {}).then((result) => {
            if (result && result.status === true) {
                setCityDetail(result.entity)
            } else {
                notify(result.message)

            }
        })
        handleGetStudentList()
    }, [])
    const notify = (msg) => toast.error(
        msg,
        {
            pauseOnHover: false,
            autoClose: 4000,
            position: 'bottom-center'
        }
    );
    const notifySuccess = (msg) => toast.success(
        msg,
        {
            pauseOnHover: false,
            autoClose: 4000,
            position: 'bottom-center'
        }
    );
    const handleModalOpen = () => {
        setSelectedValue('')
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
        setUserProfileDetail((prev) => ({
            ...prev,
            "user_login_id": null,
            "password": null,
            "user_name": null,
            "user_gender": null,
            "user_grade": null,
            "user_age": null,
            "user_phone": null
        }))
    };
    const handleCenterOpen = () => {
        setCenterOpen(true);
    };
    const handleCenterClose = () => {
        setCenterOpen(false);
    };
    const handleOpenMenu = (event, value) => {
        setSelectedStudent(value)
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = studentList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
        Logger("filterName.length", filterName.length);
        if (filterName.length >= 3) {
            handleGetStudentList()
        }
        if (filterName.length === 1) {
            // setFilterName('');
            handleGetStudentList()
        }
    };

    const handleGetStudentList = () => {
        setIsloading(true)
        appApi(url.USERS_LIST, "POST", { 'Content-Type': 'application/json' }, JSON.stringify({ "filter_name": filterName.length === 1 ? '' : filterName, "user_role": "3", "my_user_role": localStorage.getItem('role'), "filter_user_id": JSON.parse(userDtl).user.id, 'center_id': props?.centerDtl?.center_id }))
            .then((result) => {
                setIsloading(false)
                Logger("handleGetTeacherList", result);
                if (result && result.status === true) {
                    setStudentList(result.entity)
                }
            })
    }

    const handleGetStudentBlock = () => {
        const data = {
            'user_id': selectedStudent.user_id,
            'status': selectedStudent.status,
            'is_deleted': null
        }
        appApi(url.USERS_BLOCK, "DELETE", { 'Content-Type': 'application/json' }, JSON.stringify(data))
            .then((result) => {
                Logger("handleGetTeacherList", result);
                if (result && result.status === true) {
                    handleCloseMenu()
                    handleGetStudentList()
                    notifySuccess(selectedStudent.status === true ? "Blocked" : "Un-Blocked")

                }
            })
    }

    const handleGetStudentDelete = () => {
        const data = {
            'user_id': selectedStudent.user_id,
            'status': null,
            'is_deleted': selectedStudent.is_deleted
        }
        Logger("Student Delete>>>>>>>", data);
        appApi(url.USERS_BLOCK, "DELETE", { 'Content-Type': 'application/json' }, JSON.stringify(data))
            .then((result) => {
                Logger("handleGetTeacherList", result);
                if (result && result.status === true) {
                    handleCloseMenu()
                    handleGetStudentList()
                    notifySuccess('Student Deleted')
                    setOpen(false)
                }
            })
    }

    const handleCreateUser = () => {
        const data = {
            ...userProfileDetail,
            user_city: props.centerDtl.center_location,
            createdBy: JSON.parse(userDtl).user.id
        }
        Logger("handle Create user", userProfileDetail, data, JSON.parse(userDtl).user.id, props.centerDtl.center_id)
        if (userProfileDetail.user_name !== '' && userProfileDetail.user_name !== null) {
            appApi(url.CREATE_USERS, "POST", { 'Content-Type': 'application/json' }, JSON.stringify(data))
                .then((result) => {
                    if (result && result.status === true) {
                        handleModalClose()
                        handleGetStudentList()
                        notifySuccess("Student created successfully")
                    } else {
                        notify(result.message)
                    }
                })
        }
        else {
            notify('Student Name is Mandatory')
        }
    }

    const updateUser = () => {
        const data = {
            ...userProfileDetail, createdBy: JSON.parse(userDtl).user.id
        }
        Logger("handle Create user", data, JSON.parse(userDtl).user.id)

        appApi(url.UPDATE_USERS, "PUT", { 'Content-Type': 'application/json' }, JSON.stringify(userProfileDetail))
            .then((result) => {
                if (result && result.status === true) {
                    handleModalClose()
                    handleGetStudentList()
                    notifySuccess("Student Updated successfully")
                } else {
                    notify(result.message)
                }
            })
    }

    const editStudent = (data) => {
        if (data !== undefined) {
            const editData = {
                'user_login_id': data?.user?.user_login_id,
                'user_name': data?.user?.student_detail?.user_name,
                'user_phone': data?.user?.student_detail?.user_phone,
                'user_age': data?.user?.student_detail?.user_age,
                'user_gender': data?.user?.student_detail?.user_gender,
                'user_city': data?.user?.student_detail?.user_city,
                'password': data?.user?.password
            }
            Logger("partner user id>>>>>>>>>", editData, data?.user?.password);
            setSelectedValue(data?.user?.student_detail?.user_gender)
            setUserProfileDetail((prev) => ({
                ...prev,
                'user_id': data?.user?.id,
                'user_login_id': data?.user?.user_login_id,
                'user_name': data?.user?.student_detail?.user_name,
                'user_phone': data?.user?.student_detail?.user_phone,
                'user_age': data?.user?.student_detail?.user_age,
                'user_gender': data?.user?.student_detail?.user_gender,
                'user_grade': data?.user?.student_detail?.user_grade,
                'user_city': data?.user?.student_detail?.user_city,
                'center_id': data?.user?.user_center_detail?.center_id,
                'password': data?.user?.password,
                'user_role': '3'
            }))
        }
    }

    useEffect(() => {
        Logger("list>>>>>>>>", studentList[editIndex], editIndex);
        editStudent(studentList[editIndex])
    }, [editIndexTrigger])
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - studentList.length) : 0;

    // const filteredUsers = applySortFilter(studentList, getComparator(order, orderBy), filterName);

    // const isNotFound = !filteredUsers.length && !!filterName;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        // color: "primary.mainSecond",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return (
        <>
            <Helmet>
                <title> Student </title>
            </Helmet>

            <Container>
                <Card>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mr={5}>
                        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} title={"Student"} />
                        <Typography variant='h4'>{props?.centerDtl?.center_name}</Typography>
                        < Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => {
                            handleModalOpen()
                            setUserProfileDetail((prev) => ({
                                ...prev,
                                "user_login_id": null,
                                "password": null,
                                "user_name": null,
                                "user_gender": null,
                                "user_grade": null,
                                "user_age": null,
                                "user_phone": null
                            }))
                        }}>
                            Add Student
                        </Button>
                    </Stack>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={studentList.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {studentList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                        const { id, name, role, status, company, avatarUrl, isVerified } = row;

                                        const selectedUser = selected.indexOf(row?.user?.student_detail?.user_name) !== -1;

                                        return (
                                            <TableRow hover tabIndex={-1} role="checkbox" selected={selectedUser}>


                                                <TableCell component="th" >

                                                    <Typography variant="subtitle2" noWrap>
                                                        {row?.user?.student_detail?.user_name || "-"}
                                                    </Typography>

                                                </TableCell>

                                                <TableCell align="left">{row?.user?.student_detail?.user_city || "-"}</TableCell>

                                                <TableCell align="left">{row?.user?.student_detail?.user_phone || "-"}</TableCell>

                                                <TableCell align="left">{row?.user?.student_detail?.user_grade || "-"}</TableCell>
                                                <TableCell align="left">{row?.user?.user_center_detail?.center_detail?.center_name || "-"}</TableCell>

                                                <TableCell align="left">
                                                    <Label color={(row?.user?.user_blocked === true ? 'error' : 'success')}>{sentenceCase((row?.user?.user_blocked === true ? "blocked" : "Active"))}</Label>
                                                </TableCell>

                                                <TableCell align="right">
                                                    <IconButton size="large" color="inherit" onClick={(event) => {
                                                        handleOpenMenu(event, { user_id: row?.user?.id, status: !row?.user?.user_blocked, is_deleted: !row?.user?.is_deleted })
                                                        if (page > 0) {
                                                            setEditIndex(page * rowsPerPage + i)
                                                            setEditIndexTrigger(editIndexTrigger + 1)
                                                            Logger("page>>>>>>", page, rowsPerPage, page * rowsPerPage + i);
                                                        }
                                                        else {
                                                            setEditIndex(i)
                                                            setEditIndexTrigger(editIndexTrigger + 1)
                                                            Logger("page>>>>>>", page, rowsPerPage, i);
                                                        }
                                                    }}>
                                                        <Iconify icon={'eva:more-vertical-fill'} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {studentList && studentList.length === 0 && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        {isLoading ? 'Loading .....' : 'No Data Found'}
                                                    </Typography>

                                                    {/* <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography> */}
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={studentList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container >

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => {
                    handleModalOpen()
                    setIsEdit(true)
                }}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
                {
                    role && role === '0' ?
                        <MenuItem onClick={handleGetStudentDelete} sx={{color: 'error.main'}}>
                            <Iconify icon={'eva:close-circle-fill'} sx={{ mr: 2 }} />
                            Delete
                        </MenuItem>
                        : ""
                }
                <MenuItem sx={{ color: (selectedStudent && selectedStudent.status === true) ? "green" : 'error.main' }} onClick={handleGetStudentBlock}>
                    <Box display={"flex"} alignItems={"center"}>
                        <BlockOutlined sx={{ mr: 1.5 }} />
                        {selectedStudent && selectedStudent.status === true ? "Blocked" : "Un-Blocked"}
                    </Box>
                </MenuItem>
            </Popover>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
            >
                <Box sx={{ ...style, display: "flex", flexDirection: "column", height: '80%' }}>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box flexGrow={1}>
                            <Typography>
                                Profile Detail
                            </Typography>
                        </Box>
                        <Box flexGrow={0}>
                            <IconButton onClick={handleModalClose}>
                                <CancelOutlined />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Box display={"flex"} alignItems={"center"} flexDirection={"column"} height={'100%'} overflow={'scroll'} >
                        <List >
                            {/* <ListItem>
                                <TextField
                                    required
                                    label={"Login ID"}
                                    name="user_login_id"
                                    placeholder='Enter Login Id'
                                    // variant="outlined"
                                    // focused
                                    onChange={(event) => {
                                        setUserProfileDetail((pre) => ({
                                            ...pre,
                                            user_login_id: event.target.value
                                        }))
                                    }}
                                />
                            </ListItem> */}
                            <ListItem>
                                <TextField
                                    required
                                    label={"Name"}
                                    name="user_name"
                                    // variant="outlined"
                                    placeholder='Enter Username'
                                    value={userProfileDetail.user_name}
                                    // focused
                                    // autoFocus={false}
                                    onChange={(event) => {
                                        setUserProfileDetail((pre) => ({
                                            ...pre,
                                            user_name: event.target.value
                                        }))
                                    }}
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    label={"Phone"}
                                    name="user_phone"
                                    placeholder='Enter Phone number'
                                    type='number'
                                    value={userProfileDetail.user_phone}
                                    // variant="outlined"
                                    // focused
                                    onChange={(event) => {
                                        setUserProfileDetail((pre) => ({
                                            ...pre,
                                            user_phone: event.target.value
                                        }))
                                    }}
                                />
                            </ListItem>
                            {/* <ListItem>
                                <TextField
                                    required
                                    label={"Password"}
                                    name="password"
                                    placeholder='Enter Password'
                                    // variant="outlined"
                                    // focused
                                    onChange={(event) => {
                                        setUserProfileDetail((pre) => ({
                                            ...pre,
                                            password: event.target.value
                                        }))
                                    }}
                                />
                            </ListItem> */}
                            <ListItem>
                                <TextField
                                    select
                                    fullWidth
                                    label={"Gender"}
                                    name="user_gender"
                                    value={userProfileDetail.user_gender}
                                    // variant="outlined"
                                    // focused
                                    onChange={(event) => {
                                        setUserProfileDetail((pre) => ({
                                            ...pre,
                                            user_gender: event.target.value
                                        }))
                                    }
                                    }
                                >
                                    <MenuItem value={'Male'}>Male</MenuItem>
                                    <MenuItem value={'Female'} >Female</MenuItem>
                                    <MenuItem value={'Transgender'}>Transgender</MenuItem>
                                </TextField>
                            </ListItem>
                            <ListItem>
                                <TextField
                                    label={"Age"}
                                    name="user_age"
                                    placeholder='Enter age'
                                    type='number'
                                    // variant="outlined"
                                    // focused
                                    value={userProfileDetail.user_age}
                                    onChange={(event) => {
                                        setUserProfileDetail((pre) => ({
                                            ...pre,
                                            user_age: event.target.value
                                        }))
                                    }}
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    label={"Grade"}
                                    name="user_grade"
                                    // variant="outlined"
                                    placeholder='Enter Grade'
                                    value={userProfileDetail.user_grade}
                                    // focused
                                    // autoFocus={false}
                                    onChange={(event) => {
                                        setUserProfileDetail((pre) => ({
                                            ...pre,
                                            user_grade: event.target.value
                                        }))
                                    }}
                                />
                            </ListItem>
                            {/* <ListItem>
                                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src='assets/feedback/fiprint.webp' alt='fprint' width={'50%'} />
                                    <Button variant='text'>Scan Fingerprint</Button>
                                </Box>
                            </ListItem> */}
                            {/* <ListItem>
                                <Box sx={{ display: 'flex', gap: 2, flexDirection: "column" }}>
                                    <Typography>
                                        Gender :
                                    </Typography>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Radio
                                            checked={selectedValue === 'Male'}
                                            onChange={(event) => {
                                                setUserProfileDetail((pre) => ({
                                                    ...pre,
                                                    user_gender: event.target.value
                                                }))
                                                setSelectedValue(event.target.value);
                                            }}

                                            value="Male"

                                        />
                                        <Typography>
                                            Male
                                        </Typography>


                                        <Radio
                                            checked={selectedValue === 'Female'}
                                            onChange={(event) => {
                                                setUserProfileDetail((pre) => ({
                                                    ...pre,
                                                    user_gender: event.target.value
                                                }))
                                                setSelectedValue(event.target.value);
                                            }}

                                            value="Female"

                                        />
                                        <Typography>
                                            Female
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Radio
                                            checked={selectedValue === 'Transgender'}
                                            onChange={(event) => {
                                                setUserProfileDetail((pre) => ({
                                                    ...pre,
                                                    user_gender: event.target.value
                                                }))
                                                setSelectedValue(event.target.value);
                                            }}

                                            value="Transgender"

                                        />
                                        <Typography >
                                            Transgender
                                        </Typography>
                                    </Box>
                                </Box>
                            </ListItem> */}
                            {/* <ListItem>
                                <TextField
                                    required
                                    select
                                    fullWidth
                                    label={"City"}
                                    name="user_city"
                                    // variant="outlined"
                                    // focused
                                    onChange={(event) => {
                                        setUserProfileDetail((pre) => ({
                                            ...pre,
                                            user_city: event.target.value
                                        }))
                                    }
                                    }
                                >
                                    {cityDetail && cityDetail.length !== 0 ? cityDetail.map((e, i) => {
                                        return <MenuItem value={e.id} >{e.city}</MenuItem>
                                    }) : <MenuItem value="">none</MenuItem>}
                                </TextField>
                            </ListItem>
                            <ListItem>
                                <TextField
                                    required
                                    select
                                    fullWidth
                                    label={"Center"}
                                    placeholder='Select center'
                                    name="center_id"
                                    onChange={(event) => {
                                        setUserProfileDetail((pre) => ({
                                            ...pre,
                                            center_id: event.target.value
                                        }))
                                    }
                                    }

                                >

                                    {centerDetail && centerDetail.length !== 0 ? centerDetail.map((e, i) => {
                                        return <MenuItem value={e.id} >{e.center_name}</MenuItem>
                                    }) : <MenuItem value="">none</MenuItem>}

                                </TextField>
                            </ListItem> */}






                        </List>

                        <Button
                            onClick={() => {
                                if (isEdit) {
                                    updateUser()
                                }
                                else {
                                    handleCreateUser()
                                }
                            }}
                            sx={{
                                color: "common.black",
                                paddingLeft: "10%",
                                paddingRight: "10%",
                                "&:hover": {
                                    backgroundColor: "button.primary"
                                },
                                bgcolor: "button.primary"
                            }}>
                            {isEdit ? 'Update' : 'Create'}
                        </Button>


                    </Box>
                </Box>
            </Modal >

            <ToastContainer />
        </>
    );
}
