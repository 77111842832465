import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import Login from './layouts/login/login';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import MainLanding from './layouts/main';
import SupportMain from './layouts/support/main';
import AboutMain from './layouts/about/main';
import FeedbackMain from './layouts/feedback/main';
import StudentPage from './pages/studentPage';
import TeacherPage from './pages/teacherPage';
import CenterPage from './pages/centerPage';
import GalleryPage from './pages/galleryPage';
import FaqMain from './layouts/faq/main';
import ContactUsMain from './layouts/contact/main';
import { AuthuProtectedRoute } from './protectedRoute';
import PartnerToCenter from './pages/partnertocenter';
import StudentPortal from './layouts/studentPortal/main';
import GalleryMain from './layouts/gallery/main';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to="landing" />, index: true }
      ]
    },
    {
      path: '/dashboard',
      element: <AuthuProtectedRoute><DashboardLayout /></AuthuProtectedRoute>,
      // element: <MainLanding />,
      children: [
        { element: <AuthuProtectedRoute> <Navigate to="/dashboard/admin" /></AuthuProtectedRoute>, index: true },
        { path: 'admin', element: <AuthuProtectedRoute> <DashboardAppPage /></AuthuProtectedRoute> },
        { path: 'partner/main', element: <AuthuProtectedRoute><DashboardAppPage /></AuthuProtectedRoute> },
        { path: 'student/main', element: <AuthuProtectedRoute><DashboardAppPage /></AuthuProtectedRoute> },
        { path: 'teacher/main', element: <AuthuProtectedRoute> <DashboardAppPage /></AuthuProtectedRoute> },
        { path: 'partner/main/center', element: <AuthuProtectedRoute> <PartnerToCenter /></AuthuProtectedRoute> },
        { path: 'partner', element: <AuthuProtectedRoute> <UserPage /></AuthuProtectedRoute> },
        { path: 'student', element: <AuthuProtectedRoute>< StudentPage /> </AuthuProtectedRoute> },
        { path: 'teacher', element: <AuthuProtectedRoute><TeacherPage /></AuthuProtectedRoute> },
        { path: 'center', element: <AuthuProtectedRoute> <CenterPage /> </AuthuProtectedRoute> },
        {path: 'gallery', element: <AuthuProtectedRoute> <GalleryPage /> </AuthuProtectedRoute> },
        { path: 'dashboard', element: <AuthuProtectedRoute> <DashboardLayout /> </AuthuProtectedRoute> },
        // { path: 'user', element: <UserPage /> },
        { path: 'products', element: <AuthuProtectedRoute><ProductsPage /> </AuthuProtectedRoute> },
        { path: 'blog', element: <AuthuProtectedRoute> <BlogPage />  </AuthuProtectedRoute> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'landing',
      element: <MainLanding />
    },
    {
      path: 'support',
      element: <SupportMain />
    },
    {
      path: 'about',
      element: <AboutMain />
    },
    {
      path: 'feedback',
      element: <FeedbackMain />
    },
    {
      path: 'contactus',
      element: <ContactUsMain />
    },
    {
      path: 'faq',
      element: <FaqMain />
    },
    {
      path: 'gallery',
      element: <GalleryMain />
    },
    {
      path: 'student-portal',
      element: <StudentPortal />
    },
    {
      element: <AuthuProtectedRoute> <SimpleLayout /></AuthuProtectedRoute>,
      children: [
        { element: <AuthuProtectedRoute> <Navigate to="/dashboard/admin" /></AuthuProtectedRoute>, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
