import Footer from "../main/footer/footer"
import DrawerAppBar from "../main/nav/mainnavbar"
import Gallery from "./gallery"

function GalleryMain() {

    return (
        <>
            <DrawerAppBar />
            <Gallery />
            <Footer />
        </>
    )
}

export default GalleryMain
