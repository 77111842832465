import { Box, Button, Grid, Stack, Typography } from "@mui/material";

function About() {

    return (
        <Box sx={{
            // height: '100%',
            // width: '100%',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'white',
            paddingBottom: 2
        }}>
            <Box display={'flex'} marginTop={4} marginBottom={4} >
                <Box display={{
                    lg: 'flex',
                    md: 'flex',
                    sm: 'none',
                    xs: 'none'
                }}>
                    <img src='assets/feedback/Group 611.svg' alt="img" />
                </Box>
                <Box sx={{ height: { lg: '100%', md: '100%', sm: '70%', xs: '60%' }, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box  >
                        <Typography variant="h4" textAlign={'center'}>About</Typography>
                        <Typography color={'background.main'}>Expeirence A Good Education</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: '100%',
                width: '100%',
                // display: 'flex',     
            }} >
                <Grid container
                    justifyContent={'center'}
                    alignItems={'center'}
                    direction={{
                        lg: 'row',
                        md: 'row',
                        sm: 'column-reverse',
                        xs: 'column-reverse'
                    }} >
                    <Grid item xs={8} >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box
                                sx={{
                                    height: {
                                        lg: '60%',
                                        md: '60%',
                                        sm: '40%',
                                        xs: '40%'
                                    },
                                    width: {
                                        lg: '60%',
                                        md: '60%',
                                        sm: '90%',
                                        xs: '90%'
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    alignSelf: "center",
                                    textAlign: "justify"
                                }} >
                                <Stack>
                                    <Typography alignSelf={'start'} variant="h3">Who We Are</Typography>
                                    <Typography variant="body1">HPE’s digital classroom solutions provide an environment that supports learner centric pedagogies, integrated curriculum and multiple assessment approach. It allows for an interactive and stimulating learning experience through collaborative and personal learning settings in virtual and physical learning spaces.</Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{
                        height: '100%',
                        width: '100%',
                    }} >
                        <Box display={'flex'} sx={{
                            height: '100%',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2,
                            position: "relative"
                        }} >
                            <Box  >
                                <img src='assets/feedback/about_us.svg' alt="img" />
                            </Box>
                            {/* <Box width={"100%"} position={"absolute"} display={"flex"} justifyContent={"end"}>
                                <img src='assets/landing/Group 636 (1).svg' alt="img" />
                            </Box> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}

export default About